/**
 * @file Redux reducer for managing flow in creation of Scratch challenge
 * and it's corresponding solution
 * @author Giovanni Bonilla
 */

import { MERGE_NINEDOTS_STATE } from './ninedots-root'

/* Action Types */
const SET_CREATE_STEP =
  'stack-gui/ninedots-root/step-in-creator-flow/SET_CREATE_STEP'
const SET_ADD_SOLUTION_STEP =
  'stack-gui/ninedots-root/step-in-creator-flow/SET_SOLUTION_STEP'

/* Constants for step modes, to be used by action creators and reducer */
const CREATOR_CHALLENGE_STEP_DEFAULT = 'CREATE_CHALLENGE_STEP'
const ADD_SOLUTION_STEP = 'ADD_SOLUTION_STEP'

/* Initial State */
const initialState = {
  step: CREATOR_CHALLENGE_STEP_DEFAULT
}

/* Reducer */
const reducer = function (state = initialState, action) {
  switch (action.type) {
    case MERGE_NINEDOTS_STATE:
      return action.stateToMerge !== undefined &&
        action.stateToMerge.stepInCreatorFlow !== undefined
        ? {
          ...state,
          ...action.stateToMerge.stepInCreatorFlow
        }
        : state
    case SET_CREATE_STEP:
      return {
        step: CREATOR_CHALLENGE_STEP_DEFAULT
      }
    case SET_ADD_SOLUTION_STEP:
      return {
        step: ADD_SOLUTION_STEP
      }
    default:
      return state
  }
}

/* Action Creators */
const setCreateStep = function () {
  return {
    type: SET_CREATE_STEP
  }
}

const setAddSolutionStep = function () {
  return {
    type: SET_ADD_SOLUTION_STEP
  }
}

export {
  reducer as default,
  initialState as creatorFlowStepInitialState,
  setCreateStep,
  setAddSolutionStep,
  CREATOR_CHALLENGE_STEP_DEFAULT,
  ADD_SOLUTION_STEP,
  SET_ADD_SOLUTION_STEP
}
