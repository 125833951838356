/**
 * @file Redux reducer tracking challenge completion status.
 * @author Julius Diaz Panoriñgan
 */

import { MERGE_NINEDOTS_STATE } from './ninedots-root'
import { SET_ADD_SOLUTION_STEP } from './step-in-creator-flow'

// action types
const COMPLETE_CHALLENGE =
  'stack-gui/ninedots-root/completion/COMPLETE_CHALLENGE'
const FAIL_CHALLENGE = 'stack-gui/ninedots-root/completion/FAIL_CHALLENGE'

// constants, to be used by action creators and reducer

// initial state
const initialState = {
  completion: false,
  message: ''
}

// reducer
const reducer = function (state = initialState, action) {
  // on loading a project,
  // (or adding an example solution)
  // reset completion status
  if ([MERGE_NINEDOTS_STATE, SET_ADD_SOLUTION_STEP].includes(action.type)) {
    return initialState
  }

  switch (action.type) {
    case COMPLETE_CHALLENGE:
      return {
        completion: true,
        message: action.message
      }
    case FAIL_CHALLENGE:
      return {
        completion: false,
        message: action.message
      }
    default:
      return state
  }
}

// action creators
const completeChallenge = function (message) {
  return {
    type: COMPLETE_CHALLENGE,
    message
  }
}
const failChallenge = function (message) {
  return {
    type: FAIL_CHALLENGE,
    message
  }
}

export {
  reducer as default,
  initialState as completionInitialState,
  completeChallenge,
  failChallenge
  // constants
}
