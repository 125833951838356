exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ .menu_menu_3k7QT {\n    position: absolute;\n    border: 1px solid hsla(0, 0%, 0%, 0.15);\n    border-radius: 0 0 8px 8px;\n    background-color: hsla(215, 100%, 65%, 1);\n    padding: 0;\n    margin: 0;\n    min-width: 186px;\n    max-width: 260px;\n    overflow: visible;\n    color: hsla(0, 100%, 100%, 1);\n    -webkit-box-shadow: 0 8px 8px 0 hsla(0, 0%, 0%, 0.15);\n            box-shadow: 0 8px 8px 0 hsla(0, 0%, 0%, 0.15);\n} .menu_menu_3k7QT.menu_left_dujsV {\n    right: 0;\n} .menu_menu_3k7QT.menu_right_3PQ4S {\n    left: 0;\n} .menu_menu-item_3EwYA {\n    display: block;\n    line-height: 34px;\n    white-space: nowrap;\n    padding: 0 10px;\n} .menu_menu-item_3EwYA.menu_active_ebXO6,\n.menu_menu-item_3EwYA:hover {\n    background-color: hsla(0, 0%, 0%, 0.15);\n} .menu_menu-section_2U-v6 {\n    border-top: 1px solid hsla(0, 0%, 0%, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"menu": "menu_menu_3k7QT",
	"left": "menu_left_dujsV",
	"right": "menu_right_3PQ4S",
	"menu-item": "menu_menu-item_3EwYA",
	"menuItem": "menu_menu-item_3EwYA",
	"active": "menu_active_ebXO6",
	"menu-section": "menu_menu-section_2U-v6",
	"menuSection": "menu_menu-section_2U-v6"
};