/**
 * @file A modal displaying challenge feedback (from challenge complete/fail
 * blocks) to the user. This component is presentational.
 * @author Julius Diaz Panoriñgan
 */

import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import Box from '../../../components/box/box.jsx'

import styles from './completion-modal.css'

const completeModalLabel = 'Challenge Complete'
const incompleteModalLabel = 'Challenge Not Yet Complete'
const completeHeader = 'CHALLENGE COMPLETE!'
const incompleteHeader = 'Not there yet!'
const completeButtonTooltip = 'Continue'
const incompleteButtonTooltip = 'Try challenge again'
const completeButtonText = 'Okay!'
const incompleteButtonText = 'Try Again'

const CompletionModal = props => (
  <ReactModal
    className={styles.modalContent}
    contentLabel={props.isComplete ? completeModalLabel : incompleteModalLabel}
    isOpen={true}
    overlayClassName={styles.modalOverlay}
    onRequestClose={props.onContinue}>
    <div>
      <Box className={styles.illustration} />

      <Box className={styles.body}>
        <h2>{props.isComplete ? completeHeader : incompleteHeader}</h2>
        <p>{props.completionMessage}</p>

        <Box className={styles.buttonRow}>
          <button
            className={
              props.isComplete ? styles.okayButton : styles.tryAgainButton
            }
            title={
              props.isComplete ? completeButtonTooltip : incompleteButtonTooltip
            }
            onClick={props.onContinue}>
            {props.isComplete ? completeButtonText : incompleteButtonText}
          </button>
        </Box>
      </Box>
    </div>
  </ReactModal>
)

CompletionModal.propTypes = {
  completionMessage: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired
}

export default CompletionModal
