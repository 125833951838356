/**
 * @file Exports a function used to generate XML for a ScratchBlocks toolbox.
 * Allows enabling/disabling of categories and individual blocks.
 * @author MIT LLK and contributors
 * @author Julius Diaz Panoriñgan
 * @todo ¿refactor category generation functions?
 */

import ScratchBlocks from 'scratch-blocks';

import upperFirst from 'lodash.upperfirst';

import {
    getMotionBlockXml,
    getLooksBlockXml,
    getSoundBlockXml,
    getEventsBlockXml,
    getControlBlockXml,
    getSensingBlockXml,
    getOperatorsBlockXml,
    normalizeBlockName
} from '../ninedots/lib/block-utilities';

import {getCategoryState} from '../ninedots/reducers/api-blocks';

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

/**
 * @constant BLOCK_VIEW_MODES
 * @enum {string}
 * @exports
 */
export const BLOCK_VIEW_MODES = {
    STUDENT: 'STUDENT',
    HIDE: 'HIDE',
    SHOW: 'SHOW',
    CONFIG: 'CONFIG'
};

/**
 * An object keyed by block name indicating which blocks should be
 * enabled/disabled. Any block names absent in the object will be disabled,
 * unless all block names present in the object are true.
 * (This is to handle a potential edge case where a new block is added but
 * there is no corresponding boolean in the BlockConfig; the logic is that
 * if all present block names are true, a whole category should be enabled.)
 * @typedef {(boolean|object.<string, boolean>)} BlockConfig
 */

/**
 * Utility that checks whether the current view mode shows all blocks or not.
 * If BLOCK_VIEW_MODE.CONFIG or BLOCK_VIEW_MODE.SHOW, false.
 * If BLOCK_VIEW_MODE.HIDE or BLOCK_VIEW_MODE.STUDENT, true.
 * @param {string} blockViewMode - How to display the blocks
 * @returns {boolean} - Whether to display all blocks.
 */
const isHidingBlocks = function (blockViewMode) {
    return [BLOCK_VIEW_MODES.HIDE, BLOCK_VIEW_MODES.STUDENT].includes(blockViewMode);
};

/**
 * @function getXmlConverterFunction
 * @param {object.<string, string>} blockXml - An object, keyed by block name,
 * with properties set to corresponding ScratchBlocks XML strings.
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} category - The block category we're concerned with.
 * @param {string} blockViewMode - How to display the blocks.
 * @param {string} targetId - The target id to be used when generating XML.
 * @returns {getXmlConverterFunction~converterFxn} - A function that converts
 * (a) a block name to its XML (if enabled, or if configuration mode) or the
 * empty string (if disabled), or (b) the separator block to itself.
 */
const getXmlConverterFunction = function (blockXml, enabledBlocks, category, blockViewMode, targetId = '') {
    
    // First, determine if all blocks are enabled.
    // This preempts the edge case where a block is in blockXml but not enabledBlocks.
    // Note that if enabledBlocks is an empty object, all blocks are enabled.
    const enabledCategoryBlocks = enabledBlocks[category] || {};
    const allBlocksEnabled =
        getCategoryState(enabledBlocks, category) === true ||
        Object.keys(enabledCategoryBlocks).length === 0;

    const showAllBlocks = !isHidingBlocks(blockViewMode);

    /**
     * @function converterFxn
     * @inner
     * @param {string} block - The name of a ScratchBlock, or a separator.
     * @returns {string} - The XML of a ScratchBlock if enabled (or
     * configuration mode); an empty string for a ScratchBlock if disabled; a
     * separator block for a separator block.
     */
    const converterFxn = function (block) {
        if (block === blockSeparator) {
            return blockSeparator;
        }

        block = normalizeBlockName(block, targetId);

        if (showAllBlocks) {
            let xml = blockXml[block];

            // in configuration or show mode, we may need to toggle the block disabled status
            if (!enabledCategoryBlocks[block]) {
                xml = xml.replace(/disabled="false"/g, 'disabled="true"');
            }

            return xml;
        }

        if (allBlocksEnabled || enabledCategoryBlocks[block]) {
            return blockXml[block];
        }

        return '';
    };

    return converterFxn;
};

/**
 * @function getNoAvailableBlocksLabel
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {string} categoryName - The name of the current category
 * @returns {string} - A string that can be displayed if a category is usually
 * active, but has no current blocks because a stage/sprite is selected.
 */
const getNoAvailableBlocksLabel = function (isStage, categoryName) {
    return `<label text="${isStage ? 'Stage' : 'Sprite'} selected: no ${categoryName} blocks"></label>`;
};

/**
 * Given a BlockConfig and a category name, generates XML with informational
 * labels (whether all category is enabled, partially enabled, or disabled)
 * and buttons (to enable or disable the entire category).
 * @function getInfoConfigElements
 * @param {BlockConfig} enabledBlocks - The BlockConfig for the category.
 * @param {string} categoryName - The category to generate XML for.
 * @param {boolean} getConfigButtons - Whether to get toggle buttons, i.e. view mode is configuration mode.
 * @returns {string} - XML to be passed to Scratch-Blocks/Blockly.
 */
const getInfoConfigElements = function (enabledBlocks, categoryName, getConfigButtons = false) {
    const elements = [];
    // const areAllEnabled = areAllBlocks(enabledBlocks, true);
    const areAllDisabled = getCategoryState(enabledBlocks, categoryName) === false;

    /*
        This is old code for showing status labels.
        In the future, we may again want to output elements even if we are not
        in full-on configuration mode.
    */
    /* //
    if (areAllEnabled) {
        elements.push(`<label text="${categoryName} Enabled" />`);
    } else if (areAllDisabled) {
        elements.push(`<label text="${categoryName} Disabled" />`);
    } else {
        elements.push(`<label text="${categoryName} Partially Enabled" />`);
    }
    // */

    /* Push either a 'hide' or 'show' blockly element if in config mode */
    const upperCaseName = upperFirst(categoryName);
    if (getConfigButtons) {
        if (areAllDisabled) {
            elements.push(`<hide callbackKey="enable${upperCaseName}" />`);
        } else {
            elements.push(`<show callbackKey="disable${upperCaseName}" />`);
        }
    }
    
    return elements.join('\n');
};

/**
 * Generates XML for the motion category.
 * @function motion
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {string} targetId - The current editing target
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of motion blocks.
 */
const motion = function (isStage, targetId, enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'motion') === false) return '';

    const motionBlocks = [
        'motion_movesteps',
        'motion_turnright',
        'motion_turnleft',
        blockSeparator,
        'motion_goto',
        'motion_gotoxy',
        'motion_glideto',
        'motion_glidesecstoxy',
        blockSeparator,
        'motion_pointindirection',
        'motion_pointtowards',
        blockSeparator,
        'motion_changexby',
        'motion_setx',
        'motion_changeyby',
        'motion_sety',
        blockSeparator,
        'motion_ifonedgebounce',
        blockSeparator,
        'motion_setrotationstyle',
        blockSeparator,
        'motion_xposition',
        'motion_yposition',
        'motion_direction'
    ];

    /*
    This is a remnant of the original motion function. We keep and use it
    instead of getNoAvailableBlocksLabel because (a) the logic for determining
    that there are no blocks is very simple here (just relies on isStage) and
    (b) we can continue to take advantage of existing translations.
    */
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    
    const motionBlocksXmlStr = motionBlocks
        .map(getXmlConverterFunction(
            getMotionBlockXml(targetId),
            enabledBlocks,
            'motion',
            blockViewMode,
            targetId
        ))
        .join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Motion', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${isStage ? `<label text="${stageSelected}"></label>` : motionBlocksXmlStr}
        ${categorySeparator}
    </category>
    // */

    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${getInfoConfigElements(enabledBlocks, 'motion', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${isStage ? `<label text="${stageSelected}"></label>` : motionBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the looks category.
 * @function looks
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {string} targetId - The current editing target
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of looks blocks.
 */
const looks = function (isStage, targetId, enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'looks') === false) return '';

    const looksSpriteBlocks = [
        'looks_sayforsecs',
        'looks_say',
        'looks_thinkforsecs',
        'looks_think',
        blockSeparator,
        'looks_switchcostumeto',
        'looks_nextcostume',
        'looks_switchbackdropto',
        'looks_nextbackdrop',
        blockSeparator,
        'looks_changesizeby',
        'looks_setsizeto',
        blockSeparator,
        'looks_changeeffectby',
        'looks_seteffectto',
        'looks_cleargraphiceffects',
        blockSeparator,
        'looks_show',
        'looks_hide',
        blockSeparator,
        'looks_gotofrontback',
        'looks_goforwardbackwardlayers',
        'looks_costumenumbername',
        'looks_backdropnumbername',
        'looks_size'
    ];

    const looksStageBlocks = [
        'looks_switchbackdropto',
        'looks_switchbackdroptoandwait',
        'looks_nextbackdrop',
        blockSeparator,
        'looks_changeeffectby',
        'looks_seteffectto',
        'looks_cleargraphiceffects',
        blockSeparator,
        'looks_backdropnumbername'
    ];

    const looksBlocks = isStage ?
        looksStageBlocks :
        looksSpriteBlocks;
    
    const looksBlocksXmlArr = looksBlocks
        .map(getXmlConverterFunction(
            getLooksBlockXml(targetId),
            enabledBlocks,
            'looks',
            blockViewMode,
            targetId
        ))
        .filter(xml => (xml !== ''));

    const looksBlocksXmlStr = looksBlocksXmlArr.every(xml => (xml === blockSeparator)) ?
        getNoAvailableBlocksLabel(isStage, 'looks') :
        looksBlocksXmlArr.join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Looks', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${looksBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
    // */

    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${getInfoConfigElements(enabledBlocks, 'looks', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${looksBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the sound category.
 * @function sound
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {string} targetId - The current editing target
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of sound blocks.
 */
const sound = function (isStage, targetId, enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'sound') === false) return '';

    const soundBlocks = [
        'sound_playuntildone',
        'sound_play',
        'sound_stopallsounds',
        blockSeparator,
        'sound_changeeffectby',
        'sound_seteffectto',
        'sound_cleareffects',
        blockSeparator,
        'sound_changevolumeby',
        'sound_setvolumeto',
        'sound_volume'
    ];

    const soundBlocksXmlStr = soundBlocks
        .map(getXmlConverterFunction(
            getSoundBlockXml(targetId),
            enabledBlocks,
            'sound',
            blockViewMode,
            targetId
        ))
        .join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Sound', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${soundBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
    // */

    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
        ${getInfoConfigElements(enabledBlocks, 'sound', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${soundBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the events category.
 * @function events
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of events blocks.
 */
const events = function (isStage, enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'events') === false) return '';

    const eventBlocks = [
        'event_whenflagclicked',
        'event_whenkeypressed',
        isStage ? 'event_whenstageclicked' : 'event_whenthisspriteclicked',
        'event_whenbackdropswitchesto',
        blockSeparator,
        'event_whengreaterthan',
        blockSeparator,
        'event_whenbroadcastreceived',
        'event_broadcast',
        'event_broadcastandwait'
    ];

    const eventBlocksXmlArr = eventBlocks
        .map(getXmlConverterFunction(
            getEventsBlockXml(),
            enabledBlocks,
            'events',
            blockViewMode
        ))
        .filter(xml => (xml !== ''));

    const eventBlocksXmlStr = eventBlocksXmlArr.every(xml => (xml === blockSeparator)) ?
        getNoAvailableBlocksLabel(isStage, 'events') :
        eventBlocksXmlArr.join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Events', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${eventBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
    // */

    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${getInfoConfigElements(enabledBlocks, 'events', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${eventBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the control category.
 * @function control
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of control blocks.
 */
const control = function (isStage, enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'control') === false) return '';

    const controlStageBlocks = [
        'control_wait',
        blockSeparator,
        'control_repeat',
        'control_forever',
        blockSeparator,
        'control_if',
        'control_if_else',
        'control_wait_until',
        'control_repeat_until',
        blockSeparator,
        'control_stop',
        blockSeparator,
        'control_create_clone_of'
    ];

    const controlSpriteBlocks = [
        'control_wait',
        blockSeparator,
        'control_repeat',
        'control_forever',
        blockSeparator,
        'control_if',
        'control_if_else',
        'control_wait_until',
        'control_repeat_until',
        blockSeparator,
        'control_stop',
        blockSeparator,
        'control_start_as_clone',
        'control_create_clone_of',
        'control_delete_this_clone'
    ];

    const controlBlocks = isStage ?
        controlStageBlocks :
        controlSpriteBlocks;

    const controlBlocksXmlArr = controlBlocks
        .map(getXmlConverterFunction(
            getControlBlockXml(),
            enabledBlocks,
            'control',
            blockViewMode
        ))
        .filter(xml => (xml !== ''));

    const controlBlocksXmlStr = controlBlocksXmlArr.every(xml => (xml === blockSeparator)) ?
        getNoAvailableBlocksLabel(isStage, 'control') :
        controlBlocksXmlArr.join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Control', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${controlBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
    // */

    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        ${getInfoConfigElements(enabledBlocks, 'control', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${controlBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the sensing category.
 * @function sensing
 * @param {boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of sensing blocks.
 */
const sensing = function (isStage, enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'sensing') === false) return '';

    const sensingStageBlocks = [
        'sensing_askandwait',
        'sensing_answer',
        blockSeparator,
        'sensing_keypressed',
        'sensing_mousedown',
        'sensing_mousex',
        'sensing_mousey',
        blockSeparator,
        'sensing_loudness',
        blockSeparator,
        'sensing_timer',
        'sensing_resettimer',
        blockSeparator,
        'sensing_of',
        blockSeparator,
        'sensing_current',
        'sensing_dayssince2000',
        blockSeparator,
        'sensing_username'
    ];

    const sensingSpriteBlocks = [
        'sensing_touchingobject',
        'sensing_touchingcolor',
        'sensing_coloristouchingcolor',
        'sensing_distanceto',
        blockSeparator,
        'sensing_askandwait',
        'sensing_answer',
        blockSeparator,
        'sensing_keypressed',
        'sensing_mousedown',
        'sensing_mousex',
        'sensing_mousey',
        blockSeparator,
        'sensing_setdragmode',
        blockSeparator,
        blockSeparator,
        'sensing_loudness',
        blockSeparator,
        'sensing_timer',
        'sensing_resettimer',
        blockSeparator,
        'sensing_of',
        blockSeparator,
        'sensing_current',
        'sensing_dayssince2000',
        blockSeparator,
        'sensing_username'
    ];
    
    const sensingBlocks = isStage ?
        sensingStageBlocks :
        sensingSpriteBlocks;
    
    const sensingBlocksXmlArr = sensingBlocks
        .map(getXmlConverterFunction(
            getSensingBlockXml(),
            enabledBlocks,
            'sensing',
            blockViewMode
        ))
        .filter(xml => (xml !== ''));

    const sensingBlocksXmlStr = sensingBlocksXmlArr.every(xml => (xml === blockSeparator)) ?
        getNoAvailableBlocksLabel(isStage, 'sensing') :
        sensingBlocksXmlArr.join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Sensing', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${sensingBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
    // */

    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${getInfoConfigElements(enabledBlocks, 'sensing', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${sensingBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the operators category.
 * @function operators
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of operators blocks.
 */
const operators = function (enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    // return empty string early if all blocks in category are disabled
    if (isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'operators') === false) return '';

    const operatorBlocks = [
        'operator_add',
        'operator_subtract',
        'operator_multiply',
        'operator_divide',
        blockSeparator,
        'operator_random',
        blockSeparator,
        'operator_gt',
        'operator_lt',
        'operator_equals',
        blockSeparator,
        'operator_and',
        'operator_or',
        'operator_not',
        blockSeparator,
        'operator_join',
        'operator_letter_of',
        'operator_length',
        'operator_contains',
        blockSeparator,
        'operator_mod',
        'operator_round',
        blockSeparator,
        'operator_mathop'
    ];

    const operatorBlocksXmlStr = operatorBlocks
        .map(getXmlConverterFunction(
            getOperatorsBlockXml(),
            enabledBlocks,
            'operators',
            blockViewMode
        ))
        .join('\n');

    // old xml with render of configuration elements in show hidden mode
    /* //
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        ${isHidingBlocks(blockViewMode) ?
        '' :
        getInfoConfigElements(enabledBlocks, 'Operators', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${operatorBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
    // */

    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        ${getInfoConfigElements(enabledBlocks, 'operators', blockViewMode === BLOCK_VIEW_MODES.CONFIG)}
        ${operatorBlocksXmlStr}
        ${categorySeparator}
    </category>
    `;
};

/**
 * Generates XML for the variables category. Note that this is only the category
 * tag; the contents are generated via a callback passed to
 * Scratch-Blocks/Blockly from src/containers/blocks.jsx.
 * @function variables
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of variables blocks.
 */
const variables = function (enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    return isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'variables') === false ?
        '' :
        `<category
            name="%{BKY_CATEGORY_VARIABLES}"
            id="variables"
            colour="#FF8C1A"
            secondaryColour="#DB6E00"
            custom="VARIABLE">
        </category>`;
};

/**
 * Generates XML for the my blocks category. Note that this is only the category
 * tag; the contents are generated via a callback passed to
 * Scratch-Blocks/Blockly from src/containers/blocks.jsx.
 * @function myBlocks
 * @param {BlockConfig} enabledBlocks - What should be enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of myBlocks blocks.
 */
const myBlocks = function (enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    return isHidingBlocks(blockViewMode) && getCategoryState(enabledBlocks, 'myBlocks') === false ?
        '' :
        `<category
            name="%{BKY_CATEGORY_MYBLOCKS}"
            id="myBlocks"
            colour="#FF6680"
            secondaryColour="#FF4D6A"
            custom="PROCEDURE">
        </category>`;
};

/**
 * Generates XML for the completion category.
 * If the creator if viewing, this should always be active but not configurable.
 * If a student is viewing, this should never be visible.
 * @function completion
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - The XML for a ScratchBlocks category of completion blocks.
 */
const completion = function (blockViewMode = BLOCK_VIEW_MODES.HIDE) {
    return [BLOCK_VIEW_MODES.CONFIG, BLOCK_VIEW_MODES.STUDENT].includes(blockViewMode) ?
        '' : `
    <category name="Completion" id="completion" colour="#7B849D" secondaryColour="#6C7693">
        <block type="completion_complete">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">challenge complete</field>
                </shadow>
            </value>
        </block>
        <block type="completion_fail">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">challenge failed</field>
                </shadow>
            </value>
        </block>
    </category>
    `;
};

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * Generates toolbox XML for ScratchBlocks.
 * @function makeToolBoxXML
 * @exports makeToolBoxXML
 * @param {!boolean} isStage - Whether the toolbox is for a stage-type target.
 * @param {?string} targetId - The current editing target
 * @param {string?} categoriesXML - null for default toolbox, or an XML string with <category> elements.
 * @param {object.<string, (boolean|BlockConfig)>} enabledBlocks - an object
 * indicating which categories (and which of their blocks) should be
 * enabled/disabled.
 * @param {string} blockViewMode - How to display the blocks.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const makeToolboxXML = function (isStage, targetId, categoriesXML,
    enabledBlocks = {}, blockViewMode = BLOCK_VIEW_MODES.HIDE) {

    const gap = [categorySeparator];

    const everything = [
        xmlOpen,
        motion(isStage, targetId, enabledBlocks, blockViewMode),
        gap,
        looks(isStage, targetId, enabledBlocks, blockViewMode),
        gap,
        sound(isStage, targetId, enabledBlocks, blockViewMode),
        gap,
        events(isStage, /* targetId, */ enabledBlocks, blockViewMode),
        gap,
        control(isStage, /* targetId, */ enabledBlocks, blockViewMode),
        gap,
        sensing(isStage, /* targetId, */ enabledBlocks, blockViewMode),
        gap,
        operators(/* isStage, targetId, */ enabledBlocks, blockViewMode),
        gap,
        variables(/* isStage, targetId, */ enabledBlocks, blockViewMode),
        gap,
        myBlocks(/* isStage, targetId, */ enabledBlocks, blockViewMode),
        gap,
        completion(blockViewMode),
        gap
    ];

    if (categoriesXML) {
        everything.push(gap, categoriesXML);
    }

    everything.push(xmlClose);
    return everything.join('\n');
    
};

export default makeToolboxXML;
