/**
 * @file A helper that loads/stores assets from Firebase.
 * @author Julius Diaz Panoriñgan
 */

import nets from 'nets'

import ScratchStorage from 'scratch-storage'

import { firebaseStorage, firestore } from './firebase'

const Asset = ScratchStorage.Asset
const AssetType = ScratchStorage.AssetType

const collectionName =
  process.env.CLOUD_FIRESTORE_COLLECTION_NAME || 'devprojectmetadata'

/**
 * Pulled from
 * https://github.com/LLK/scratch-storage/blob/19f4699ac49070958551348742308b0716090975/src/Helper.js
 * since the interface isn't exported.
 * @interface Helper
 * @classdesc Base class for asset load/save helpers.
 */
class Helper {
  constructor (parent) {
    this.parent = parent
  }

  /**
   * Fetch an asset but don't process dependencies.
   * @param {AssetType} assetType - The type of asset to fetch.
   * @param {string} assetId - The ID of the asset to fetch: a project ID, MD5, etc.
   * @param {DataFormat} dataFormat - The file format / file extension of the asset to fetch: PNG, JPG, etc.
   * @returns {Promise.<Asset>} A promise for the contents of the asset.
   */
  load (assetType, assetId, dataFormat) {
    return Promise.reject(
      new Error(
        `No asset of type ${assetType} for ID ${assetId} with format ${dataFormat}`
      )
    )
  }
}

/**
 * @class {FirebaseHelper} FirebaseHelper
 * @classdesc An implementation of the Helper interface that loads/stores assets
 * (currently just graphical assets) from/to Cloud Storage for Firebase.
 * @exports FirebaseHelper
 */
class FirebaseHelper extends Helper {
  constructor (parent) {
    super(parent)
    this.firebaseStorage = firebaseStorage
    this.firestore = firestore
  }

  /**
   * Fetches an asset from Cloud Storage for Firebase, if possible. Aims to
   * mirror the implementation of WebHelper.load in scratch-storage as much as
   * possible, including the use of nets.
   * @function load
   * @param {AssetType} assetType - The type of asset to fetch.
   * @param {string} assetId - The ID of the asset to fetch: a project ID, MD5, etc.
   * @param {DataFormat} dataFormat - The file format / file extension of the asset to fetch: PNG, JPG, etc.
   * @returns {Promise.<Asset>} A promise for the contents of the asset.
   */
  load (assetType, assetId, dataFormat) {
    const asset = new Asset(assetType, assetId, dataFormat)
    const isProject = assetType === AssetType.Project
    const assetFolder = isProject ? 'projects' : 'assets'
    const assetExtension = isProject ? 'sb3' : dataFormat

    switch (assetType) {
      case AssetType.ImageBitmap:
      case AssetType.ImageVector:
      case AssetType.Project:
        return this.firebaseStorage
          .ref()
          .child(`${assetFolder}/${assetId}.${assetExtension}`)
          .getDownloadURL()
          .then(
            url =>
              new Promise((resolve, reject) => {
                nets({ method: 'get', url }, (err, resp, body) => {
                  // body is a Buffer
                  if (err || Math.floor(resp.statusCode / 100) !== 2) {
                    resolve(null)
                  } else {
                    asset.setData(body, dataFormat)
                    resolve(asset)
                  }
                })
              }),
            error => Promise.reject(error)
          )
      default:
        return Promise.resolve(null)
    }
  }

  /**
   * Stores an asset on Cloud Storage for Firebase. Currently, the returned
   * promise does not provide any status (beyond resolved/rejected), unlike
   * WebHelper.store in scratch-storage, which provides an HTTP response body.
   * It __does not__ upload the asset if an asset with a matching ID is found on
   * the Cloud Storage instance (i.e. a download URL is successfully gotten).
   * It __does__ upload a project, on the other hand, if a project asset file
   * already exists.
   * For new projects, this also stores basic metadata on Cloud Firestore. It
   * also includes code to lazily migrate old metadata on Cloud Storage to
   * Firestore; we may get rid of this functionality eventually.
   *
   * @function store
   * @param {AssetType} assetType - The type of asset to store.
   * @param {DataFormat} dataFormat - The file format / file extension of the asset to store: PNG, JPG, etc.
   * @param {Uint8Array} data - The file data.
   * @param {string} assetId - The ID of the asset to store: a project ID, MD5, etc.
   * @returns {Promise} A promise indicating the success of the attempted
   * storage.
   */
  store (assetType, dataFormat, data, assetId) {
    switch (assetType) {
      case AssetType.ImageBitmap:
      case AssetType.ImageVector:
        return new Promise((resolve, reject) => {
          const assetRef = this.firebaseStorage
            .ref()
            .child(`assets/${assetId}.${dataFormat}`)
          assetRef
            .getDownloadURL()
            .then(resolve) // resolve if download URL gotten
            .catch(() => {
              // attempt upload if no download URL gotten
              const uploadTask = assetRef.put(data)
              uploadTask.on('state_changed', {
                error: reject,
                complete: resolve
              })
            })
        })

      case AssetType.Project:
        return new Promise((resolve, reject) => {
          const firestoreDocRef = this.firestore.doc(
            `${collectionName}/${assetId}.${dataFormat}`
          )
          const storageRef = this.firebaseStorage
            .ref()
            .child(`projects/${assetId}.${dataFormat}`)

          // check for metadata in firestore
          firestoreDocRef
            .get()
            .then(projectMetadata => projectMetadata.exists)
            .then(metadataExists => {
              // if metadata exists go straight to uploading
              if (metadataExists) {
                return
              }
              // else put metadata in firestore before uploading
              return storageRef
                .getMetadata()
                .then(
                  metadata => metadata.customMetadata || null,
                  () => ({ type: 'TEMPLATE' })
                )
                .then(metadata =>
                  firestoreDocRef.set(metadata || { type: 'TEMPLATE' })
                )
            })
            .then(() => {
              const uploadTask = storageRef.put(data)
              uploadTask.on('state_changed', {
                error: reject,
                complete: resolve
              })
            })
        })
      default:
        return Promise.reject(new Error('invalid asset type'))
    }
  }

  /**
   * Loads project metadata from Google Cloud Firestore; the metadata object is
   * essentially the document referenced.
   * @param {string} assetId - the id of the asset whose metadata we're loading
   * @returns {Promise} A promise that resolves with a metadata object, or
   * rejects on an error.
   */
  loadMetadata (assetId) {
    return this.firestore
      .doc(`${collectionName}/${assetId}.sb3`)
      .get()
      .then(docSnapshot => docSnapshot.data())
  }

  /**
   * Stores project metadata to Google Cloud Firestore; the metadata object
   * provided is merged with the existing data in the Firestore document,
   * with the exception of configurations for custom variables,
   * which need configs for deleted variables cleared out.
   * @param {object} metadata - an object with asset metadata
   * @param {string} assetId - the id of the asset whose metadata we're storing
   * @returns {Promise} A promise indicating the success of the attempted
   * storage.
   */
  storeMetadata (metadata, assetId) {
    const docRef = this.firestore.doc(`${collectionName}/${assetId}.sb3`)

    return (
      this.firestore
        .batch()
        // merge everything!
        .set(docRef, metadata, { merge: true })
        // overwrite custom block configurations!
        /** @todo custom procedures too, not just variables */
        .update(docRef, {
          'apiBlocks.variables.dynamicBlocks':
            metadata.apiBlocks.variables.dynamicBlocks
        })
        // commit!
        .commit()
    )
  }
}

export default FirebaseHelper
