import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import Box from '../box/box.jsx';
import SpriteInfo from '../../containers/sprite-info.jsx';
import SpriteList from './sprite-list.jsx';
import ActionMenu from '../action-menu/action-menu.jsx';
import {STAGE_DISPLAY_SIZES} from '../../lib/layout-constants';
import {isRtl} from 'scratch-l10n';

import styles from './sprite-selector.css';

import fileUploadIcon from '../action-menu/icon--file-upload.svg';
import paintIcon from '../action-menu/icon--paint.svg';
import spriteIcon from '../action-menu/Dog_tan.svg';
import surpriseIcon from '../action-menu/icon--surprise.svg';
import searchIcon from '../action-menu/icon--search.svg';

import hiddenIcon from '../sprite-info/icon--hide.svg';

const messages = defineMessages({
    addSpriteFromLibrary: {
        id: 'gui.spriteSelector.addSpriteFromLibrary',
        description: 'Button to add a sprite in the target pane from library',
        defaultMessage: 'Choose a Sprite'
    },
    addSpriteFromPaint: {
        id: 'gui.spriteSelector.addSpriteFromPaint',
        description: 'Button to add a sprite in the target pane from paint',
        defaultMessage: 'Paint'
    },
    addSpriteFromSurprise: {
        id: 'gui.spriteSelector.addSpriteFromSurprise',
        description: 'Button to add a random sprite in the target pane',
        defaultMessage: 'Surprise'
    },
    addSpriteFromFile: {
        id: 'gui.spriteSelector.addSpriteFromFile',
        description: 'Button to add a sprite in the target pane from file',
        defaultMessage: 'Upload Sprite'
    }
});

const SpriteSelectorComponent = function (props) {
    const {
        editingTarget,
        hoveredTarget,
        intl,
        onChangeSpriteDirection,
        onChangeSpriteName,
        onChangeSpriteRotationStyle,
        onChangeSpriteSize,
        onChangeSpriteVisibility,
        onChangeSpriteX,
        onChangeSpriteY,
        onDrop,
        onDeleteSprite,
        onDuplicateSprite,
        onExportSprite,
        onFileUploadClick,
        onNewSpriteClick,
        onPaintSpriteClick,
        onSelectSprite,
        onSpriteUpload,
        onSurpriseSpriteClick,
        raised,
        selectedId,
        spriteFileInput,
        sprites,
        stageSize,
        isShowHiddenMode,
        isPointerConfigMode,
        onSpriteMenuClick,
        spriteMenuEnabled,
        ...componentProps
    } = props;
    let selectedSprite = sprites[selectedId];
    let spriteInfoDisabled = false;
    if (typeof selectedSprite === 'undefined') {
        selectedSprite = {};
        spriteInfoDisabled = true;
    }

    /*
        set img, buttons, and title tooltip depending on pointer mode and
        whether the sprite menu is enabled or not
    */

    // if sprite menu disabled, indicate hidden status w/ alternate icon
    const img = spriteMenuEnabled ?
        spriteIcon :
        hiddenIcon;

    // if in hide/lock mode, disable extra float-out menu options
    const moreButtons = isPointerConfigMode ?
        null :
        [
            {
                title: intl.formatMessage(messages.addSpriteFromFile),
                img: fileUploadIcon,
                onClick: onFileUploadClick,
                fileAccept: '.svg, .png, .jpg, .jpeg, .sprite2, .sprite3',
                fileChange: onSpriteUpload,
                fileInput: spriteFileInput
            }, {
                title: intl.formatMessage(messages.addSpriteFromSurprise),
                img: surpriseIcon,
                onClick: onSurpriseSpriteClick // TODO need real function for this
            }, {
                title: intl.formatMessage(messages.addSpriteFromPaint),
                img: paintIcon,
                onClick: onPaintSpriteClick // TODO need real function for this
            }, {
                title: intl.formatMessage(messages.addSpriteFromLibrary),
                img: searchIcon,
                onClick: onNewSpriteClick
            }
        ];

    // display appropriate tooltip text
    const title = isPointerConfigMode ?
        (spriteMenuEnabled ? 'Disable Sprite Menu' : 'Enable Sprite Menu') :
        intl.formatMessage(messages.addSpriteFromLibrary); // default message

    return (
        <Box
            className={styles.spriteSelector}
            {...componentProps}
        >

            <SpriteInfo
                direction={selectedSprite.direction}
                disabled={spriteInfoDisabled}
                name={selectedSprite.name}
                rotationStyle={selectedSprite.rotationStyle}
                size={selectedSprite.size}
                stageSize={stageSize}
                visible={selectedSprite.visible}
                x={selectedSprite.x}
                y={selectedSprite.y}
                onChangeDirection={onChangeSpriteDirection}
                onChangeName={onChangeSpriteName}
                onChangeRotationStyle={onChangeSpriteRotationStyle}
                onChangeSize={onChangeSpriteSize}
                onChangeVisibility={onChangeSpriteVisibility}
                onChangeX={onChangeSpriteX}
                onChangeY={onChangeSpriteY}
            />

            <Box className={styles.scrollWrapper}>
                <SpriteList
                    editingTarget={editingTarget}
                    hoveredTarget={hoveredTarget}
                    items={Object.keys(sprites).map(id => sprites[id])}
                    raised={raised}
                    selectedId={selectedId}
                    onDeleteSprite={onDeleteSprite}
                    onDrop={onDrop}
                    onDuplicateSprite={onDuplicateSprite}
                    onExportSprite={onExportSprite}
                    onSelectSprite={onSelectSprite}
                />
            </Box>
            {(isShowHiddenMode || spriteMenuEnabled) &&
                <ActionMenu
                    className={styles.addButton}
                    img={img}
                    moreButtons={moreButtons}
                    title={title}
                    tooltipPlace={isRtl(intl.locale) ? 'right' : 'left'}
                    onClick={onSpriteMenuClick}
                />
            }
        </Box>
    );
};

SpriteSelectorComponent.propTypes = {
    editingTarget: PropTypes.string,
    hoveredTarget: PropTypes.shape({
        hoveredSprite: PropTypes.string,
        receivedBlocks: PropTypes.bool
    }),
    intl: intlShape.isRequired,
    isPointerConfigMode: PropTypes.bool.isRequired,
    isShowHiddenMode: PropTypes.bool.isRequired,
    onChangeSpriteDirection: PropTypes.func,
    onChangeSpriteName: PropTypes.func,
    onChangeSpriteRotationStyle: PropTypes.func,
    onChangeSpriteSize: PropTypes.func,
    onChangeSpriteVisibility: PropTypes.func,
    onChangeSpriteX: PropTypes.func,
    onChangeSpriteY: PropTypes.func,
    onDeleteSprite: PropTypes.func,
    onDrop: PropTypes.func,
    onDuplicateSprite: PropTypes.func,
    onExportSprite: PropTypes.func,
    onFileUploadClick: PropTypes.func,
    onNewSpriteClick: PropTypes.func,
    onPaintSpriteClick: PropTypes.func,
    onSelectSprite: PropTypes.func,
    onSpriteMenuClick: PropTypes.func.isRequired,
    onSpriteUpload: PropTypes.func,
    onSurpriseSpriteClick: PropTypes.func,
    raised: PropTypes.bool,
    selectedId: PropTypes.string,
    spriteFileInput: PropTypes.func,
    spriteMenuEnabled: PropTypes.bool.isRequired,
    sprites: PropTypes.shape({
        id: PropTypes.shape({
            costume: PropTypes.shape({
                url: PropTypes.string,
                name: PropTypes.string.isRequired,
                bitmapResolution: PropTypes.number.isRequired,
                rotationCenterX: PropTypes.number.isRequired,
                rotationCenterY: PropTypes.number.isRequired
            }),
            name: PropTypes.string.isRequired,
            order: PropTypes.number.isRequired
        })
    }),
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired
};

export default injectIntl(SpriteSelectorComponent);
