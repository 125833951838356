import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import Box from '../../../components/box/box.jsx'
import styles from './revise-challenge-modal.css'

const ReviseChallengeModal = props => (
  <ReactModal
    isOpen
    className={styles.modalContent}
    overlayClassName={styles.modalOverlay}
    onRequestClose={props.onCancel} >
    <div dir={'ltr'} >
      <Box className={styles.illustration} />

      <Box className={styles.body}>
        <h2>
          {'Are you sure you want to go back and edit challenge?'}
        </h2>
        <Box className={styles.buttonRow}>
          <button
            className={styles.noButton}
            onClick={props.onCancel}>
            {'Cancel'}
          </button>
          <button
            className={styles.viewProjectButton}
            onClick={props.onClickYes}>
            {'Yes'}
          </button>
        </Box>
      </Box>
    </div>
  </ReactModal>
)

ReviseChallengeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired
}

export default ReviseChallengeModal
