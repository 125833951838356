/**
 * @file A modal displaying challenge feedback (from challenge complete/fail
 * blocks) to the user. This is the container component.
 * @author Julius Diaz Panoriñgan
 */

// import bindAll from 'lodash.bindall'
// import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import CompletionModalComponent from '../components/completion-modal/completion-modal.jsx'

import { closeCompletionModal } from '../../reducers/modals'

class CompletionModal extends React.Component {
  constructor (props) {
    super(props)
    // bindAll(this, ['handleContinue'])
    this.handleContinue = this.handleContinue.bind(this)
  }

  handleContinue () {
    this.props.onContinue()
  }

  render () {
    return (
      <CompletionModalComponent
        completionMessage={this.props.completionMessage}
        isComplete={this.props.isComplete}
        onContinue={this.handleContinue} />
    )
  }
}

// the presentational CompletionModalComponent has PropTypes covered
CompletionModal.propTypes = {
  ...CompletionModalComponent.propTypes
}

const mapStateToProps = state => ({
  completionMessage: state.scratchGui.ninedotsState.completion.message,
  isComplete: state.scratchGui.ninedotsState.completion.completion
})

const mapDispatchToProps = dispatch => ({
  onContinue: () => {
    dispatch(closeCompletionModal())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletionModal)
