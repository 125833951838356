import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CloseButton from '../close-button/close-button.jsx';
import styles from './sprite-selector-item.css';
import {ContextMenuTrigger} from 'react-contextmenu';
import {ContextMenu, MenuItem} from '../context-menu/context-menu.jsx';
import {FormattedMessage} from 'react-intl';

import draggableOffIcon from '../sprite-info/icon--draggable-off.svg';
import visibleOffIcon from '../sprite-info/icon--hide.svg';

// react-contextmenu requires unique id to match trigger and context menu
let contextMenuId = 0;

const SpriteSelectorItem = props => (
    <ContextMenuTrigger
        attributes={{
            className: classNames(props.className, styles.spriteSelectorItem, {
                [styles.isSelected]: props.selected
            }),
            onClick: props.onClick,
            onMouseEnter: props.onMouseEnter,
            onMouseLeave: props.onMouseLeave,
            onMouseDown: props.onMouseDown,
            onTouchStart: props.onMouseDown
        }}
        disable={props.dragging}
        id={`${props.name}-${contextMenuId}`}
    >
        <div className={styles.buttonIconWrapper}>
            {((props.isUserCreatorMode || props.draggableByStudent) && props.selected && props.onDeleteButtonClick) ? (
                <CloseButton
                    className={styles.deleteButton}
                    size={CloseButton.SIZE_SMALL}
                    onClick={props.onDeleteButtonClick}
                />
            ) : null }
            {props.visibleToStudent ?
                null :
                <div className={styles.viewIcon}>
                    <img src={visibleOffIcon} />
                </div>
            }
            {props.draggableByStudent ?
                null :
                <div className={styles.dragIcon}>
                    <img src={draggableOffIcon} />
                </div>
            }
        </div>
        {typeof props.number === 'undefined' ? null : (
            <div className={styles.number}>{props.number}</div>
        )}
        {props.costumeURL ? (
            <img
                className={styles.spriteImage}
                draggable={false}
                src={props.costumeURL}
            />
        ) : null}
        <div className={styles.spriteInfo}>
            <div className={styles.spriteName}>{props.name}</div>
            {props.details ? (
                <div className={styles.spriteDetails}>{props.details}</div>
            ) : null}
        </div>
        {(props.isUserCreatorMode || props.draggableByStudent) &&
            (props.onDuplicateButtonClick || props.onDeleteButtonClick || props.onExportButtonClick) ? (
                <ContextMenu id={`${props.name}-${contextMenuId++}`}>
                    {props.onDuplicateButtonClick ? (
                        <MenuItem onClick={props.onDuplicateButtonClick}>
                            <FormattedMessage
                                defaultMessage="duplicate"
                                description="Menu item to duplicate in the right click menu"
                                id="gui.spriteSelectorItem.contextMenuDuplicate"
                            />
                        </MenuItem>
                    ) : null}
                    {props.onDeleteButtonClick ? (
                        <MenuItem onClick={props.onDeleteButtonClick}>
                            <FormattedMessage
                                defaultMessage="delete"
                                description="Menu item to delete in the right click menu"
                                id="gui.spriteSelectorItem.contextMenuDelete"
                            />
                        </MenuItem>
                    ) : null }
                    {props.onExportButtonClick ? (
                        <MenuItem onClick={props.onExportButtonClick}>
                            <FormattedMessage
                                defaultMessage="export"
                                description="Menu item to export the selected item"
                                id="gui.spriteSelectorItem.contextMenuExport"
                            />
                        </MenuItem>
                    ) : null }
                </ContextMenu>
            ) : null}
    </ContextMenuTrigger>
);

SpriteSelectorItem.propTypes = {
    className: PropTypes.string,
    costumeURL: PropTypes.string,
    details: PropTypes.string,
    draggableByStudent: PropTypes.bool,
    dragging: PropTypes.bool,
    isUserCreatorMode: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.number,
    onClick: PropTypes.func,
    onDeleteButtonClick: PropTypes.func,
    onDuplicateButtonClick: PropTypes.func,
    onExportButtonClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    visibleToStudent: PropTypes.bool
};

export default SpriteSelectorItem;
