import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import VM from 'scratch-vm';

import storage from '../lib/storage';
import {
    LoadingStates,
    createProject,
    defaultProjectId,
    doneCreatingProject,
    doneUpdatingProject,
    doneUpdatingProjectWithNewId,
    getIsCreating,
    getIsCreatingSolution,
    getIsShowingProject,
    getIsShowingWithoutId,
    getIsUpdating,
    projectError,
    updateProject
} from '../reducers/project-state';
import {
    closeLoadingProject
} from '../reducers/modals';

import {
    CREATOR_CHALLENGE_STEP_DEFAULT
} from '../ninedots/reducers/step-in-creator-flow';

import {
    setUserMode,
    USER_MODE_STUDENT
} from '../ninedots/reducers/interface-mode';
/**
 * Higher Order Component to provide behavior for saving projects.
 * @param {React.Component} WrappedComponent the component to add project saving functionality to
 * @returns {React.Component} WrappedComponent with project saving functionality added
 *
 * <ProjectSaverHOC>
 *     <WrappedComponent />
 * </ProjectSaverHOC>
 */
const ProjectSaverHOC = function (WrappedComponent) {
    class ProjectSaverComponent extends React.Component {
        componentDidUpdate (prevProps) {
            if (this.props.isUpdating && !prevProps.isUpdating) {
                // The id is either the default project id,
                // or null if a project has been loaded from an .sbe file.
                if (this.props.reduxProjectId === defaultProjectId || this.props.reduxProjectId === null) {
                    // Get new project id and save project.
                    fetch('/internalapi/newProjectId', {
                        method: 'GET',
                        headers: {'Content-Type': 'application/json'}
                    })
                        .then(response => response.json())
                        .then(newProjectId => {
                            this.storeProject(newProjectId)
                                // store metadata after storing project
                                .then(() => this.storeProjectMetadata(newProjectId, this.props.ninedotsMetadata))
                                .then(() => {
                                    /** @todo Add some kind of UI confirmation. */
                                    this.props.onUpdatedProjectWithoutId(this.props.loadingState, newProjectId);
                                    window.location = `#${newProjectId}`;
                                })
                                .catch(err => {
                                    // NOTE: should throw up a notice for user
                                    this.props.onProjectError(`Saving the project failed with error: ${err}`);
                                });
                        })
                        /** @todo Add some kind of UI confirmation and direction. */
                        .catch(err => {
                            // HMM. does this trigger a report to the Scratch team?
                            this.props.onProjectError(`Saving the project failed with error: ${err}`);
                        });
                } else {
                    this.storeProject(this.props.reduxProjectId)
                        // store metadata after storing project
                        .then(() => this.storeProjectMetadata(this.props.reduxProjectId, this.props.ninedotsMetadata))
                        .then(() => {
                            // there is nothing we expect to find in response that we need to check here
                            this.props.onUpdatedProject(this.props.loadingState);
                        })
                        .catch(err => {
                            // NOTE: should throw up a notice for user
                            this.props.onProjectError(`Saving the project failed with error: ${err}`);
                        });
                }
            }

            /* If we're in creating solution step, then save the current project and make copy in
               order to create a template for creating solution */
            if (this.props.isCreatingSolution && !prevProps.isCreatingSolution) {
                this.savingAndCopyingProject();
            }
            // TODO: distinguish between creating new, remixing, and saving as a copy
            if (this.props.isCreating && !prevProps.isCreating) {
                this.storeProject()
                    /*
                    NOTE: we are not storing 9 Dots metadata here, as this code
                    branch (and the isCreating prop) is not used by the 9 Dots
                    Stack instance
                    */
                    .then(response => {
                        this.props.onCreatedProject(response.id.toString(), this.props.loadingState);
                    })
                    .catch(err => {
                        // NOTE: should throw up a notice for user
                        this.props.onProjectError(`Creating a new project failed with error: ${err}`);
                    });
            }

            // check if the project state, and user capabilities, have changed so as to indicate
            // that we should create or update project
            //
            // if we're newly able to create this project on the server, create it!
            const showingCreateable = this.props.canCreateNew && this.props.isShowingWithoutId;
            const prevShowingCreateable = prevProps.canCreateNew && prevProps.isShowingWithoutId;
            if (showingCreateable && !prevShowingCreateable) {
                this.props.onCreateProject();
            } else {
                // if we're newly able to save this project, save it!
                const showingSaveable = this.props.canSave && this.props.isShowingWithId;
                const becameAbleToSave = this.props.canSave && !prevProps.canSave;
                if (showingSaveable && becameAbleToSave) {
                    this.props.onUpdateProject();
                }
            }
        }

        /**
         * Uses scratch-vm's saveProjectSb3 to generate a file, which we then
         * upload via scratch-storage. Note that unlike the original
         * implementation, the projectId param cannot be null, because there is
         * currently no underlying functionality to create a new project with a
         * new Id.
         * @function storeProject
         * @param  {number|string} projectId Used to determine the filename
         * stored in the cloud.
         * @return {Promise} A resolved promise indicates storage success;
         * rejection indicates storage failure.
         */
        storeProject (projectId) {
            return this.props.vm.saveProjectSb3()
                .then(content => storage.store(
                    storage.AssetType.Project,
                    storage.DataFormat.SB3,
                    content,
                    projectId
                ));
        }

        /**
         * Stores project metadata in the cloud.
         * @param {number|string} projectId - Used to determine the storage
         * location in the cloud.
         * @param {object} metadata - An object to be merged in with the
         * metadata already in the cloud.
         * @returns {Promise} A resolved promise indicates storage success;
         * rejection indicates storage failure.
         */
        storeProjectMetadata (projectId, metadata) {
            /*
            note the difference between ordering of arguments to
            this.storeProjectMetadata and storage.storeMetadata; this is done
            to match the general argument order in each module
            */
            return storage.storeMetadata(metadata, projectId);
        }

        /**
         * Function for saving and copying the original project and loading the
         * solution template
         */
        savingAndCopyingProject () {
            /* Variable containing original challenge metadata. We need to modify
            'stepInCreatorFlow' prop in the ninedotsMeta to default */
            const createStepMetadata = {
                ...this.props.ninedotsMetadata,
                stepInCreatorFlow: {
                    step: CREATOR_CHALLENGE_STEP_DEFAULT
                }
            };
            const solutionStepMetadata = {
                ...this.props.ninedotsMetadata,
                type: 'SOLUTION'
            };

            /* If reduxProjectId prop is equivalent to either default project id or null
              make a GET request for new project id, otherwise resolve promise with
              reduxProjectId prop */
            const saveProjects = this.props.reduxProjectId === defaultProjectId ||
                this.props.reduxProjectId === null ?
                fetch('/internalapi/newProjectId', {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'}
                }).then(response => response.json()) :
                Promise.resolve(this.props.reduxProjectId);
                
            saveProjects
                .then(id => {
                    /* Store solution copy id in var */
                    const solutionCopyId = `${id}-solution`;

                    /* Store original project and its metadata */
                    return this.storeProject(id)
                        .then(() => this.storeProjectMetadata(id, createStepMetadata))
                        /* Store solution copy and its metadata */
                        .then(() => this.storeProject(solutionCopyId))
                        .then(() => this.storeProjectMetadata(solutionCopyId, solutionStepMetadata))
                        /* update client state */
                        .then(() => {
                            this.props.onCreatedProject(solutionCopyId, this.props.loadingState);
                            window.location = `#${solutionCopyId}`;
                            this.props.closeLoadingModal();
                        });
                })
                .catch(err => {
                    this.props.onProjectError(`Saving the project failed with error: ${err}`);
                });
        }

        render () {
            const {
                /* eslint-disable no-unused-vars */
                isCreating: isCreatingProp,
                isShowingWithId: isShowingWithIdProp,
                isShowingWithoutId: isShowingWithoutIdProp,
                isUpdating: isUpdatingProp,
                loadingState,
                onCreatedProject: onCreatedProjectProp,
                onCreateProject: onCreateProjectProp,
                onProjectError: onProjectErrorProp,
                onUpdatedProject: onUpdatedProjectProp,
                onUpdatedProjectWithoutId,
                onUpdateProject: onUpdateProjectProp,
                reduxProjectId,
                closeLoadingModal,
                isCreatingSolution: isCreatingSolutionProp,
                ninedotsMetadata,
                setUserStudent,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    {...componentProps}
                />
            );
        }
    }
    ProjectSaverComponent.propTypes = {
        canCreateNew: PropTypes.bool,
        canSave: PropTypes.bool,
        closeLoadingModal: PropTypes.func,
        isCreating: PropTypes.bool,
        isCreatingSolution: PropTypes.bool,
        isShowingWithId: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        isUpdating: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        ninedotsMetadata: PropTypes.objectOf(PropTypes.object).isRequired,
        onCreateProject: PropTypes.func,
        onCreatedProject: PropTypes.func,
        onProjectError: PropTypes.func,
        onUpdateProject: PropTypes.func,
        onUpdatedProject: PropTypes.func,
        onUpdatedProjectWithoutId: PropTypes.func.isRequired,
        reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        setPointerDefault: PropTypes.func,
        setUserStudent: PropTypes.func,
        vm: PropTypes.instanceOf(VM).isRequired
    };
    const mapStateToProps = state => {
        const loadingState = state.scratchGui.projectState.loadingState;
        return {
            isCreating: getIsCreating(loadingState),
            isCreatingSolution: getIsCreatingSolution(loadingState),
            isShowingWithId: getIsShowingProject(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            isUpdating: getIsUpdating(loadingState),
            loadingState: loadingState,
            reduxProjectId: state.scratchGui.projectState.projectId,
            vm: state.scratchGui.vm,
            ninedotsMetadata: {
                apiBlocks: state.scratchGui.ninedotsState.apiBlocks,
                stepInCreatorFlow: state.scratchGui.ninedotsState.stepInCreatorFlow,
                uiElements: state.scratchGui.ninedotsState.uiElements
            }
        };
    };
    const mapDispatchToProps = dispatch => ({
        onCreatedProject: (projectId, loadingState) => dispatch(doneCreatingProject(projectId, loadingState)),
        onCreateProject: () => dispatch(createProject()),
        onProjectError: error => dispatch(projectError(error)),
        onUpdateProject: () => dispatch(updateProject()),
        onUpdatedProject: loadingState => dispatch(doneUpdatingProject(loadingState)),
        onUpdatedProjectWithoutId: (loadingState, id) => dispatch(doneUpdatingProjectWithNewId(loadingState, id)),
        closeLoadingModal: () => dispatch(closeLoadingProject()),
        setUserStudent: () => dispatch(setUserMode(USER_MODE_STUDENT))
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ProjectSaverComponent);
};

export {
    ProjectSaverHOC as default
};
