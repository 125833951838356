import bindAll from 'lodash.bindall'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import ReviseChallengeModalComponent from '../components/revise-challenge-modal/revise-challenge-modal.jsx'

import {
  closeReviseChallengeModal
} from '../../reducers/modals'

import {
  setProjectId
} from '../../reducers/project-state'

import {
  setCreateStep
} from '../reducers/step-in-creator-flow'

class ReviseChallengeModal extends React.Component {
  constructor (props) {
    super(props)
    bindAll(this, [
      'handleCancel',
      'handleClickYes'
    ])
  }
  handleClickYes () {
    /* If we're not in setUpChallengeStep then current projectId
    is equivalent to the original project id with '-solution' appended.
    Therefore, we must remove the appended '-solution' from the string */

    /* Regex used for replacing '-solution' with an empty string */
    const regEx = /-solution/g
    const setUpChallengeId = this.props.projectId.toString().replace(regEx, '')

    /* We call setProjectId passing the setUpChallengeId as an arg
    in order to fetch the original project */
    window.location = `#${setUpChallengeId}` // triggers project id change
    this.props.onSetUpChallenge()
    this.props.closeReviseChallengeModal()
  }

  handleCancel () {
    this.props.closeReviseChallengeModal()
  }

  render () {
    return (
      <ReviseChallengeModalComponent
        onCancel={this.handleCancel}
        onClickYes={this.handleClickYes} />
    )
  }
}

ReviseChallengeModal.propTypes = {
  closeReviseChallengeModal: PropTypes.func,
  onSetUpChallenge: PropTypes.func,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setProjectId: PropTypes.func
}

const mapStateToProps = state => ({
  projectId: state.scratchGui.projectState.projectId
})

const mapDispatchToProps = dispatch => ({
  closeReviseChallengeModal: () => {
    dispatch(closeReviseChallengeModal())
  },
  setProjectId: id => {
    dispatch(setProjectId(id))
  },
  onSetUpChallenge: () => dispatch(setCreateStep())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviseChallengeModal)
