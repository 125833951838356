/**
 * @file Miscellaneous constants and utilities for working with blocks in Stack
 * / Scratch / Blockly. Includes block and category names, utilities for
 * generating Scratch-Blocks/Blockly XML, utilities for converting block names
 * for different uses and getting corresponding category names.
 * @author Julius Diaz Panoriñgan
 */

import ScratchBlocks from 'scratch-blocks'

/**
 * An object, with arrays of all the block types in a category, keyed by the
 * category name.
 * @exports
 * @constant {Object.<string, Array<string>>} blockNames
 */
export const blockNames = {
  motion: [
    'motion_movesteps',
    'motion_turnright',
    'motion_turnleft',
    'motion_goto',
    'motion_gotoxy',
    'motion_glideto',
    'motion_glidesecstoxy',
    'motion_pointindirection',
    'motion_pointtowards',
    'motion_changexby',
    'motion_setx',
    'motion_changeyby',
    'motion_sety',
    'motion_ifonedgebounce',
    'motion_setrotationstyle',
    'motion_xposition',
    'motion_yposition',
    'motion_direction'
  ],
  looks: [
    'looks_sayforsecs',
    'looks_say',
    'looks_thinkforsecs',
    'looks_think',
    'looks_switchcostumeto',
    'looks_nextcostume',
    'looks_switchbackdropto',
    'looks_switchbackdroptoandwait',
    'looks_nextbackdrop',
    'looks_changesizeby',
    'looks_setsizeto',
    'looks_changeeffectby',
    'looks_seteffectto',
    'looks_cleargraphiceffects',
    'looks_show',
    'looks_hide',
    'looks_gotofrontback',
    'looks_goforwardbackwardlayers',
    'looks_costumenumbername',
    'looks_backdropnumbername',
    'looks_size'
  ],
  sound: [
    'sound_playuntildone',
    'sound_play',
    'sound_stopallsounds',
    'sound_changeeffectby',
    'sound_seteffectto',
    'sound_cleareffects',
    'sound_changevolumeby',
    'sound_setvolumeto',
    'sound_volume'
  ],
  events: [
    'event_whenflagclicked',
    'event_whenkeypressed',
    'event_whenstageclicked',
    'event_whenthisspriteclicked',
    'event_whenbackdropswitchesto',
    'event_whengreaterthan',
    'event_whenbroadcastreceived',
    'event_broadcast',
    'event_broadcastandwait'
  ],
  control: [
    'control_wait',
    'control_repeat',
    'control_forever',
    'control_if',
    'control_if_else',
    'control_wait_until',
    'control_repeat_until',
    'control_stop',
    'control_start_as_clone',
    'control_create_clone_of',
    'control_delete_this_clone'
  ],
  sensing: [
    'sensing_touchingobject',
    'sensing_touchingcolor',
    'sensing_coloristouchingcolor',
    'sensing_distanceto',
    'sensing_askandwait',
    'sensing_answer',
    'sensing_keypressed',
    'sensing_mousedown',
    'sensing_mousex',
    'sensing_mousey',
    'sensing_setdragmode',
    'sensing_loudness',
    'sensing_timer',
    'sensing_resettimer',
    'sensing_of',
    'sensing_current',
    'sensing_dayssince2000',
    'sensing_username'
  ],
  operators: [
    'operator_add',
    'operator_subtract',
    'operator_multiply',
    'operator_divide',
    'operator_random',
    'operator_gt',
    'operator_lt',
    'operator_equals',
    'operator_and',
    'operator_or',
    'operator_not',
    'operator_join',
    'operator_letter_of',
    'operator_length',
    'operator_contains',
    'operator_mod',
    'operator_round',
    'operator_mathop'
  ],
  variables: [
    'button_make_a_variable',
    'data_setvariableto',
    'data_changevariableby',
    'data_showvariable',
    'data_hidevariable',
    'button_make_a_list',
    'data_addtolist',
    'data_deleteoflist',
    'data_deletealloflist',
    'data_insertatlist',
    'data_replaceitemoflist',
    'data_itemoflist',
    'data_itemnumoflist',
    'data_lengthoflist',
    'data_listcontainsitem',
    'data_showlist',
    'data_hidelist'
  ],
  myBlocks: ['button_make_a_block']
}

/**
 * An array of all the names of static categories (i.e. not the dynamic
 * Variables and My Blocks categories).
 * @exports
 * @constant {Array.<string>} staticCategories
 */
export const staticCategories = [
  'motion',
  'looks',
  'sound',
  'events',
  'control',
  'sensing',
  'operators'
]

/**
 * An array of all the names of dynamic categories
 * (i.e. Variables and My Blocks).
 * @exports
 * @constant {Array.<string>} staticCategories
 */
export const dynamicCategories = ['variables', 'myBlocks']

/**
 * An object of the (Blockly/ScratchBlocks) DataCategory method names used for
 * adding given variable blocks to flyout XML.
 * @exports
 * @constant {Object.<string, string>} variableDataCategoryMethodNames
 */
export const variableDataCategoryMethodNames = {
  data_setvariableto: 'addSetVariableTo',
  data_changevariableby: 'addChangeVariableBy',
  data_showvariable: 'addShowVariable',
  data_hidevariable: 'addHideVariable',
  data_addtolist: 'addAddToList',
  data_deleteoflist: 'addDeleteOfList',
  data_deletealloflist: 'addDeleteAllOfList',
  data_insertatlist: 'addInsertAtList',
  data_replaceitemoflist: 'addReplaceItemOfList',
  data_itemoflist: 'addItemOfList',
  data_itemnumoflist: 'addItemNumberOfList',
  data_lengthoflist: 'addLengthOfList',
  data_listcontainsitem: 'addListContainsItem',
  data_showlist: 'addShowList',
  data_hidelist: 'addHideList'
}

/**
 * Given a targetId, returns an XML string to be used to generate the motion
 * category of toolbox blocks in Scratch-Blocks/Blockly, with the targetId
 * spliced in appropriately for sprite- or stage-specific blocks.
 * @exports
 * @function getMotionBlockXml
 * @param {string} targetId
 * @returns {string}
 */
export const getMotionBlockXml = function (targetId) {
  return {
    motion_movesteps: `<block type="motion_movesteps" disabled="false">
                        <value name="STEPS">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">10</field>
                          </shadow>
                        </value>
                      </block>`,
    motion_turnright: `<block type="motion_turnright" disabled="false">
                        <value name="DEGREES">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">15</field>
                          </shadow>
                        </value>
                      </block>`,
    motion_turnleft: `<block type="motion_turnleft" disabled="false">
                        <value name="DEGREES">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">15</field>
                          </shadow>
                        </value>
                      </block>`,
    motion_goto: `<block type="motion_goto" disabled="false">
                    <value name="TO">
                      <shadow type="motion_goto_menu" disabled="false">
                      </shadow>
                    </value>
                  </block>`,
    motion_gotoxy: `<block type="motion_gotoxy" disabled="false">
                      <value name="X">
                        <shadow id="movex" type="math_number" disabled="false">
                          <field name="NUM">0</field>
                        </shadow>
                      </value>
                      <value name="Y">
                        <shadow id="movey" type="math_number" disabled="false">
                          <field name="NUM">0</field>
                        </shadow>
                      </value>
                    </block>`,
    motion_glideto: `<block type="motion_glideto" id="motion_glideto" disabled="false">
                      <value name="SECS">
                        <shadow type="math_number" disabled="false">
                          <field name="NUM">1</field>
                        </shadow>
                      </value>
                      <value name="TO">
                        <shadow type="motion_glideto_menu" disabled="false">
                        </shadow>
                      </value>
                    </block>`,
    motion_glidesecstoxy: `<block type="motion_glidesecstoxy" disabled="false">
                            <value name="SECS">
                              <shadow type="math_number" disabled="false">
                                <field name="NUM">1</field>
                              </shadow>
                            </value>
                            <value name="X">
                              <shadow id="glidex" type="math_number" disabled="false">
                                <field name="NUM">0</field>
                              </shadow>
                            </value>
                            <value name="Y">
                              <shadow id="glidey" type="math_number" disabled="false">
                                <field name="NUM">0</field>
                              </shadow>
                            </value>
                          </block>`,
    motion_pointindirection: `<block type="motion_pointindirection" disabled="false">
                                <value name="DIRECTION">
                                  <shadow type="math_angle" disabled="false">
                                    <field name="NUM">90</field>
                                  </shadow>
                                </value>
                              </block>`,
    motion_pointtowards: `<block type="motion_pointtowards" disabled="false">
                            <value name="TOWARDS">
                              <shadow type="motion_pointtowards_menu" disabled="false">
                              </shadow>
                            </value>
                          </block>`,
    motion_changexby: `<block type="motion_changexby" disabled="false">
                        <value name="DX">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">10</field>
                          </shadow>
                        </value>
                      </block>`,
    motion_setx: `<block type="motion_setx" disabled="false">
                    <value name="X">
                      <shadow id="setx" type="math_number" disabled="false">
                        <field name="NUM">0</field>
                      </shadow>
                    </value>
                  </block>`,
    motion_changeyby: `<block type="motion_changeyby" disabled="false">
                        <value name="DY">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">10</field>
                          </shadow>
                        </value>
                      </block>`,
    motion_sety: `<block type="motion_sety" disabled="false">
                    <value name="Y">
                      <shadow id="sety" type="math_number" disabled="false">
                        <field name="NUM">0</field>
                      </shadow>
                    </value>
                  </block>`,
    motion_ifonedgebounce:
      '<block type="motion_ifonedgebounce" disabled="false"/>',
    motion_setrotationstyle:
      '<block type="motion_setrotationstyle" disabled="false"/>',
    motion_xposition: `<block id="${targetId}_xposition" type="motion_xposition" disabled="false"/>`,
    motion_yposition: `<block id="${targetId}_yposition" type="motion_yposition" disabled="false"/>`,
    motion_direction: `<block id="${targetId}_direction" type="motion_direction" disabled="false"/>`
  }
}

/**
 * Given a targetId, returns an XML string to be used to generate the looks
 * category of toolbox blocks in Scratch-Blocks/Blockly, with the targetId
 * spliced in appropriately for sprite- or stage-specific blocks.
 * @exports
 * @function getLooksBlockXml
 * @param {string} targetId
 * @returns {string}
 */
export const getLooksBlockXml = function (targetId) {
  const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!')
  const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...')

  return {
    looks_sayforsecs: `<block type="looks_sayforsecs" disabled="false">
                        <value name="MESSAGE">
                          <shadow type="text" disabled="false">
                            <field name="TEXT">${hello}</field>
                          </shadow>
                        </value>
                        <value name="SECS">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">2</field>
                          </shadow>
                        </value>
                      </block>`,
    looks_say: `<block type="looks_say" disabled="false">
                  <value name="MESSAGE">
                    <shadow type="text" disabled="false">
                      <field name="TEXT">${hello}</field>
                    </shadow>
                  </value>
                </block>`,
    looks_thinkforsecs: `<block type="looks_thinkforsecs" disabled="false">
                          <value name="MESSAGE">
                            <shadow type="text" disabled="false">
                              <field name="TEXT">${hmm}</field>
                            </shadow>
                          </value>
                          <value name="SECS">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM">2</field>
                            </shadow>
                          </value>
                        </block>`,
    looks_think: `<block type="looks_think" disabled="false">
                    <value name="MESSAGE">
                      <shadow type="text" disabled="false">
                        <field name="TEXT">${hmm}</field>
                      </shadow>
                    </value>
                  </block>`,
    looks_switchcostumeto: `<block id="${targetId}_switchcostumeto" type="looks_switchcostumeto" disabled="false">
                              <value name="COSTUME">
                                <shadow type="looks_costume" disabled="false"/>
                              </value>
                            </block>`,
    looks_nextcostume: '<block type="looks_nextcostume" disabled="false"/>',
    looks_switchbackdropto: `<block type="looks_switchbackdropto" disabled="false">
                              <value name="BACKDROP">
                                <shadow type="looks_backdrops" disabled="false"/>
                              </value>
                            </block>`,
    looks_switchbackdroptoandwait: `<block type="looks_switchbackdroptoandwait" disabled="false">
                                      <value name="BACKDROP">
                                        <shadow type="looks_backdrops" disabled="false"/>
                                      </value>
                                    </block>`,
    looks_nextbackdrop: '<block type="looks_nextbackdrop" disabled="false"/>',
    looks_changesizeby: `<block type="looks_changesizeby" disabled="false">
                          <value name="CHANGE">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM">10</field>
                            </shadow>
                          </value>
                        </block>`,
    looks_setsizeto: `<block type="looks_setsizeto" disabled="false">
                        <value name="SIZE">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">100</field>
                          </shadow>
                        </value>
                      </block>`,
    looks_changeeffectby: `<block type="looks_changeeffectby" disabled="false">
                            <value name="CHANGE">
                              <shadow type="math_number" disabled="false">
                                <field name="NUM">25</field>
                              </shadow>
                            </value>
                          </block>`,
    looks_seteffectto: `<block type="looks_seteffectto" disabled="false">
                          <value name="VALUE">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM">0</field>
                            </shadow>
                          </value>
                        </block>`,
    looks_cleargraphiceffects:
      '<block type="looks_cleargraphiceffects" disabled="false"/>',
    looks_show: '<block type="looks_show" disabled="false"/>',
    looks_hide: '<block type="looks_hide" disabled="false"/>',
    looks_gotofrontback: '<block type="looks_gotofrontback" disabled="false"/>',
    looks_goforwardbackwardlayers: `<block type="looks_goforwardbackwardlayers" disabled="false">
                                      <value name="NUM">
                                        <shadow type="math_integer" disabled="false">
                                          <field name="NUM">1</field>
                                        </shadow>
                                      </value>
                                    </block>`,
    looks_costumenumbername: `<block id="${targetId}_costumenumbername" type="looks_costumenumbername" disabled="false"/>`,
    looks_backdropnumbername:
      '<block id="backdropnumbername" type="looks_backdropnumbername" disabled="false"/>',
    looks_size: `<block id="${targetId}_size" type="looks_size" disabled="false"/>`
  }
}

/**
 * Given a targetId, returns an XML string to be used to generate the sound
 * category of toolbox blocks in Scratch-Blocks/Blockly, with the targetId
 * spliced in appropriately for sprite- or stage-specific blocks.
 * @exports
 * @function getSoundBlockXml
 * @param {string} targetId
 * @returns {string}
 */
export const getSoundBlockXml = function (targetId) {
  return {
    sound_playuntildone: `<block id="${targetId}_sound_playuntildone" type="sound_playuntildone" disabled="false">
                            <value name="SOUND_MENU">
                              <shadow type="sound_sounds_menu" disabled="false"/>
                            </value>
                          </block>`,
    sound_play: `<block id="${targetId}_sound_play" type="sound_play" disabled="false">
                  <value name="SOUND_MENU">
                    <shadow type="sound_sounds_menu" disabled="false"/>
                  </value>
                </block>`,
    sound_stopallsounds: '<block type="sound_stopallsounds" disabled="false"/>',
    sound_changeeffectby: `<block type="sound_changeeffectby" disabled="false">
                            <value name="VALUE">
                              <shadow type="math_number" disabled="false">
                                <field name="NUM">10</field>
                              </shadow>
                            </value>
                          </block>`,
    sound_seteffectto: `<block type="sound_seteffectto" disabled="false">
                          <value name="VALUE">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM">100</field>
                            </shadow>
                          </value>
                        </block>`,
    sound_cleareffects: '<block type="sound_cleareffects" disabled="false"/>',
    sound_changevolumeby: `<block type="sound_changevolumeby" disabled="false">
                            <value name="VOLUME">
                              <shadow type="math_number" disabled="false">
                                <field name="NUM">-10</field>
                              </shadow>
                            </value>
                          </block>`,
    sound_setvolumeto: `<block type="sound_setvolumeto" disabled="false">
                          <value name="VOLUME">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM">100</field>
                            </shadow>
                          </value>
                        </block>`,
    sound_volume: '<block id="volume" type="sound_volume" disabled="false"/>'
  }
}

/**
 * Returns an XML string to be used to generate the events category of toolbox
 * blocks in Scratch-Blocks/Blockly.
 * @exports
 * @function getEventsBlockXml
 * @returns {string}
 */
export const getEventsBlockXml = function () {
  return {
    event_whenflagclicked:
      '<block type="event_whenflagclicked" disabled="false"/>',
    event_whenkeypressed: `<block type="event_whenkeypressed" disabled="false">
                           </block>`,
    event_whenstageclicked:
      '<block type="event_whenstageclicked" disabled="false"/>',
    event_whenthisspriteclicked:
      '<block type="event_whenthisspriteclicked" disabled="false"/>',
    event_whenbackdropswitchesto: `<block type="event_whenbackdropswitchesto" disabled="false">
                                   </block>`,
    event_whengreaterthan: `<block type="event_whengreaterthan" disabled="false">
                              <value name="VALUE">
                                <shadow type="math_number" disabled="false">
                                  <field name="NUM">10</field>
                                </shadow>
                              </value>
                            </block>`,
    event_whenbroadcastreceived: `<block type="event_whenbroadcastreceived" disabled="false">
                                  </block>`,
    event_broadcast: `<block type="event_broadcast" disabled="false">
                        <value name="BROADCAST_INPUT">
                          <shadow type="event_broadcast_menu" disabled="false"></shadow>
                        </value>
                      </block>`,
    event_broadcastandwait: `<block type="event_broadcastandwait" disabled="false">
                              <value name="BROADCAST_INPUT">
                                <shadow type="event_broadcast_menu" disabled="false"></shadow>
                              </value>
                            </block>`
  }
}

/**
 * Returns an XML string to be used to generate the control category of toolbox
 * blocks in Scratch-Blocks/Blockly.
 * @exports
 * @function getControlBlockXml
 * @returns {string}
 */
export const getControlBlockXml = function () {
  return {
    control_wait: `<block type="control_wait" disabled="false">
                    <value name="DURATION">
                      <shadow type="math_positive_number" disabled="false">
                        <field name="NUM">1</field>
                      </shadow>
                    </value>
                  </block>`,
    control_repeat: `<block type="control_repeat" disabled="false">
                      <value name="TIMES">
                        <shadow type="math_whole_number" disabled="false">
                          <field name="NUM">10</field>
                        </shadow>
                      </value>
                    </block>`,
    control_forever:
      '<block id="forever" type="control_forever" disabled="false"/>',
    control_if: '<block type="control_if" disabled="false"/>',
    control_if_else: '<block type="control_if_else" disabled="false"/>',
    control_wait_until:
      '<block id="wait_until" type="control_wait_until" disabled="false"/>',
    control_repeat_until:
      '<block id="repeat_until" type="control_repeat_until" disabled="false"/>',
    control_stop: '<block type="control_stop" disabled="false"/>',
    control_start_as_clone:
      '<block type="control_start_as_clone" disabled="false"/>',
    control_create_clone_of: `<block type="control_create_clone_of" disabled="false">
                                <value name="CLONE_OPTION">
                                  <shadow type="control_create_clone_of_menu" disabled="false"/>
                                </value>
                              </block>`,
    control_delete_this_clone:
      '<block type="control_delete_this_clone" disabled="false"/>'
  }
}

/**
 * Returns an XML string to be used to generate the sensing category of toolbox
 * blocks in Scratch-Blocks/Blockly.
 * @exports
 * @function getSensingBlockXml
 * @returns {string}
 */
export const getSensingBlockXml = function () {
  const name = ScratchBlocks.ScratchMsgs.translate(
    'SENSING_ASK_TEXT',
    "What's your name?"
  )

  return {
    sensing_touchingobject: `<block type="sensing_touchingobject" disabled="false">
                              <value name="TOUCHINGOBJECTMENU">
                                <shadow type="sensing_touchingobjectmenu" disabled="false"/>
                              </value>
                            </block>`,
    sensing_touchingcolor: `<block type="sensing_touchingcolor" disabled="false">
                              <value name="COLOR">
                                <shadow type="colour_picker" disabled="false"/>
                              </value>
                            </block>`,
    sensing_coloristouchingcolor: `<block type="sensing_coloristouchingcolor" disabled="false">
                                    <value name="COLOR">
                                      <shadow type="colour_picker" disabled="false"/>
                                    </value>
                                    <value name="COLOR2">
                                      <shadow type="colour_picker" disabled="false"/>
                                    </value>
                                  </block>`,
    sensing_distanceto: `<block type="sensing_distanceto" disabled="false">
                          <value name="DISTANCETOMENU">
                            <shadow type="sensing_distancetomenu" disabled="false"/>
                          </value>
                        </block>`,
    sensing_askandwait: `<block id="askandwait" type="sensing_askandwait" disabled="false">
                          <value name="QUESTION">
                            <shadow type="text" disabled="false">
                              <field name="TEXT">${name}</field>
                            </shadow>
                          </value>
                        </block>`,
    sensing_answer:
      '<block id="answer" type="sensing_answer" disabled="false"/>',
    sensing_keypressed: `<block type="sensing_keypressed" disabled="false">
                          <value name="KEY_OPTION">
                            <shadow type="sensing_keyoptions" disabled="false"/>
                          </value>
                        </block>`,
    sensing_mousedown: '<block type="sensing_mousedown" disabled="false"/>',
    sensing_mousex: '<block type="sensing_mousex" disabled="false"/>',
    sensing_mousey: '<block type="sensing_mousey" disabled="false"/>',
    sensing_setdragmode:
      '<block type="sensing_setdragmode" id="sensing_setdragmode" disabled="false"></block>',
    sensing_loudness:
      '<block id="loudness" type="sensing_loudness" disabled="false"/>',
    sensing_timer: '<block id="timer" type="sensing_timer" disabled="false"/>',
    sensing_resettimer: '<block type="sensing_resettimer" disabled="false"/>',
    sensing_of: `<block id="of" type="sensing_of" disabled="false">
                  <value name="OBJECT">
                    <shadow id="sensing_of_object_menu" type="sensing_of_object_menu" disabled="false"/>
                  </value>
                </block>`,
    sensing_current:
      '<block id="current" type="sensing_current" disabled="false"/>',
    sensing_dayssince2000:
      '<block type="sensing_dayssince2000" disabled="false"/>',
    sensing_username: '<block type="sensing_username" disabled="false"/>'
  }
}

/**
 * Returns an XML string to be used to generate the operators category of toolbox
 * blocks in Scratch-Blocks/Blockly.
 * @exports
 * @function getOperatorsBlockXml
 * @returns {string}
 */
export const getOperatorsBlockXml = function () {
  const apple = ScratchBlocks.ScratchMsgs.translate(
    'OPERATORS_JOIN_APPLE',
    'apple'
  )
  const banana = ScratchBlocks.ScratchMsgs.translate(
    'OPERATORS_JOIN_BANANA',
    'banana'
  )
  const letter = ScratchBlocks.ScratchMsgs.translate(
    'OPERATORS_LETTEROF_APPLE',
    'a'
  )

  return {
    operator_add: `<block type="operator_add" disabled="false">
                    <value name="NUM1">
                      <shadow type="math_number" disabled="false">
                        <field name="NUM"/>
                      </shadow>
                    </value>
                    <value name="NUM2">
                      <shadow type="math_number" disabled="false">
                        <field name="NUM"/>
                      </shadow>
                    </value>
                  </block>`,
    operator_subtract: `<block type="operator_subtract" disabled="false">
                          <value name="NUM1">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM"/>
                            </shadow>
                          </value>
                          <value name="NUM2">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM"/>
                            </shadow>
                          </value>
                        </block>`,
    operator_multiply: `<block type="operator_multiply" disabled="false">
                          <value name="NUM1">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM"/>
                            </shadow>
                          </value>
                          <value name="NUM2">
                            <shadow type="math_number" disabled="false">
                              <field name="NUM"/>
                            </shadow>
                          </value>
                        </block>`,
    operator_divide: `<block type="operator_divide" disabled="false">
                        <value name="NUM1">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM"/>
                          </shadow>
                        </value>
                        <value name="NUM2">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM"/>
                          </shadow>
                        </value>
                      </block>`,
    operator_random: `<block type="operator_random" disabled="false">
                        <value name="FROM">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">1</field>
                          </shadow>
                        </value>
                        <value name="TO">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM">10</field>
                          </shadow>
                        </value>
                      </block>`,
    operator_gt: `<block type="operator_gt" disabled="false">
                    <value name="OPERAND1">
                      <shadow type="text" disabled="false">
                        <field name="TEXT"/>
                      </shadow>
                    </value>
                    <value name="OPERAND2">
                      <shadow type="text" disabled="false">
                        <field name="TEXT">50</field>
                      </shadow>
                    </value>
                  </block>`,
    operator_lt: `<block type="operator_lt" disabled="false">
                    <value name="OPERAND1">
                      <shadow type="text" disabled="false">
                        <field name="TEXT"/>
                      </shadow>
                    </value>
                    <value name="OPERAND2">
                      <shadow type="text" disabled="false">
                        <field name="TEXT">50</field>
                      </shadow>
                    </value>
                  </block>`,
    operator_equals: `<block type="operator_equals" disabled="false">
                        <value name="OPERAND1">
                          <shadow type="text" disabled="false">
                            <field name="TEXT"/>
                          </shadow>
                        </value>
                        <value name="OPERAND2">
                          <shadow type="text" disabled="false">
                            <field name="TEXT">50</field>
                          </shadow>
                        </value>
                      </block>`,
    operator_and: '<block type="operator_and" disabled="false"/>',
    operator_or: '<block type="operator_or" disabled="false"/>',
    operator_not: '<block type="operator_not" disabled="false"/>',
    operator_join: `<block type="operator_join" disabled="false">
                      <value name="STRING1">
                        <shadow type="text" disabled="false">
                          <field name="TEXT">${apple} </field>
                        </shadow>
                      </value>
                      <value name="STRING2">
                        <shadow type="text" disabled="false">
                          <field name="TEXT">${banana}</field>
                        </shadow>
                      </value>
                    </block>`,
    operator_letter_of: `<block type="operator_letter_of" disabled="false">
                          <value name="LETTER">
                            <shadow type="math_whole_number" disabled="false">
                              <field name="NUM">1</field>
                            </shadow>
                          </value>
                          <value name="STRING">
                            <shadow type="text" disabled="false">
                              <field name="TEXT">${apple}</field>
                            </shadow>
                          </value>
                        </block>`,
    operator_length: `<block type="operator_length" disabled="false">
                        <value name="STRING">
                          <shadow type="text" disabled="false">
                            <field name="TEXT">${apple}</field>
                          </shadow>
                        </value>
                      </block>`,
    operator_contains: `<block type="operator_contains" id="operator_contains" disabled="false">
                          <value name="STRING1">
                            <shadow type="text" disabled="false">
                              <field name="TEXT">${apple}</field>
                            </shadow>
                          </value>
                          <value name="STRING2">
                            <shadow type="text" disabled="false">
                              <field name="TEXT">${letter}</field>
                            </shadow>
                          </value>
                        </block>`,
    operator_mod: `<block type="operator_mod" disabled="false">
                    <value name="NUM1">
                      <shadow type="math_number" disabled="false">
                        <field name="NUM"/>
                      </shadow>
                    </value>
                    <value name="NUM2">
                      <shadow type="math_number" disabled="false">
                        <field name="NUM"/>
                      </shadow>
                    </value>
                  </block>`,
    operator_round: `<block type="operator_round" disabled="false">
                      <value name="NUM">
                        <shadow type="math_number" disabled="false">
                          <field name="NUM"/>
                        </shadow>
                      </value>
                    </block>`,
    operator_mathop: `<block type="operator_mathop" disabled="false">
                        <value name="NUM">
                          <shadow type="math_number" disabled="false">
                            <field name="NUM"/>
                          </shadow>
                        </value>
                      </block>`
  }
}

/**
 * Given a block name from Scratch-Blocks/Blockly, and a target id, returns a
 * normalized name (matching the block type) that corresponds to the
 * block names in the {@link blockNames} constant exported by this file.
 * @function normalizeBlockName
 * @param {string} blockName
 * @param {string} targetId
 * @returns {string}
 */
export const normalizeBlockName = function (blockName, targetId = '') {
  // remove target id from blockName
  if (targetId) {
    blockName = blockName.replace(targetId, '')
  }

  // process the special cases that don't correspond to standard block names
  switch (blockName) {
    // in these cases, the target id has been removed and we're replacing it
    // with the category name
    case '_xposition':
    case '_yposition':
    case '_direction':
      return `motion${blockName}`
    case '_switchcostumeto':
    case '_costumenumbername':
    case '_size':
      return `looks${blockName}`

    // in these cases, we're switching to the "standard" block name
    // by adding the category name and an underscore
    case 'backdropnumbername':
      return 'looks_backdropnumbername'
    case 'volume':
      return 'sound_volume'
    case 'forever':
    case 'wait_until':
    case 'repeat_until':
      return `control_${blockName}`
    case 'askandwait':
    case 'answer':
    case 'loudness':
    case 'timer':
    case 'of':
    case 'current':
      return `sensing_${blockName}`

    // in these cases, the target id has been removed, and we need to remove
    // the now-leading underscore
    case '_sound_playuntildone':
    case '_sound_play':
      return blockName.slice(1)

    // our block name was good!
    default:
      return blockName
  }
}

/**
 * Essentially, the inverse of {@link normalizeBlockName}. Given a standard
 * block name and a target id, returns a block name that matches the block name
 * used in the toolbox by Scratch-Blocks/Blockly.
 * @function denormalizeBlockName
 * @param {string} blockName
 * @param {string} targetId
 * @returns {string}
 */
export const denormalizeBlockName = function (blockName, targetId = '') {
  switch (blockName) {
    // here, replace the category name with the target id
    case 'motion_xposition':
    case 'motion_yposition':
    case 'motion_direction':
      return targetId ? blockName.replace('motion', targetId) : blockName
    case 'looks_switchcostumeto':
    case 'looks_costumenumbername':
    case 'looks_size':
      return targetId ? blockName.replace('looks', targetId) : blockName

    // here, remove the leading category name and underscore
    case 'looks_backdropnumbername':
      return 'backdropnumbername' // blockName.replace('looks_', '')
    case 'sound_volume':
      return 'volume' // blockName.replace('sound_', '')
    case 'control_forever':
    case 'control_wait_until':
    case 'control_repeat_until':
      return blockName.slice(8) // blockName.replace('control_', '')
    case 'sensing_askandwait':
    case 'sensing_answer':
    case 'sensing_loudness':
    case 'sensing_timer':
    case 'sensing_of':
    case 'sensing_current':
      return blockName.slice(8) // blockName.replace('sensing_', '')

    // here, prepend the target id and an underscore
    case 'sound_playuntildone':
    case 'sound_play':
      return targetId ? `${targetId}_${blockName}` : blockName

    // good block name!
    default:
      return blockName
  }
}

/**
 * Given a standard block name, returns the corresponding category name.
 * @param {string} blockName
 * @returns {string}
 */
export const getCategoryName = function (blockName) {
  if (['button_make_a_variable', 'button_make_a_list'].includes(blockName)) {
    return 'variables'
  }

  if (blockName === 'button_make_a_block') {
    return 'myBlocks'
  }

  const prefix = blockName.split('_', 1)[0]
  switch (prefix) {
    case 'motion':
    case 'looks':
    case 'sound':
    case 'control':
    case 'sensing':
      return prefix
    case 'event':
    case 'operator':
      return prefix + 's'
    case 'data':
      return 'variables'
    default:
      return ''
  }
}
