import bindAll from 'lodash.bindall'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import SubmitModalComponent from '../components/submit-modal/submit-modal.jsx'

import { closeSubmitModal } from '../../reducers/modals'
import { LoadingState, updateProject } from '../../reducers/project-state'

class SubmitModal extends React.Component {
  constructor (props) {
    super(props)
    bindAll(this, ['handleCancel', 'handleSubmit'])
    // this tiny ephemeral slice of state doesn't need to be in redux
    // (it is basically calculated from redux state)
    this.state = {
      solutionSaveState: 'UNSAVED'
    }
  }

  // uses redux state changes to calculate the updated version of this.state.solutionSaveState
  componentDidUpdate (prevProps) {
    if (
      prevProps.loadingState === LoadingState.SHOWING_WITH_ID &&
      this.props.loadingState === LoadingState.UPDATING
    ) {
      this.setState({ solutionSaveState: 'SAVING' })
    } else if (
      prevProps.loadingState === LoadingState.UPDATING &&
      this.props.loadingState === LoadingState.SHOWING_WITH_ID
    ) {
      this.setState({ solutionSaveState: 'SAVED' })
    }
  }

  handleSubmit () {
    this.props.saveProject()
  }
  handleCancel () {
    this.props.closeSubmitModal()
  }
  render () {
    return (
      <SubmitModalComponent
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        projectId={this.props.projectId}
        solutionSaveState={this.state.solutionSaveState} />
    )
  }
}

SubmitModal.propTypes = {
  closeSubmitModal: PropTypes.func,
  saveProject: PropTypes.func
}

const mapStateToProps = state => ({
  isRtl: state.locales.isRtl,
  loadingState: state.scratchGui.projectState.loadingState,
  projectId: state.scratchGui.projectState.projectId.replace('-solution', '')
})

const mapDispatchToProps = dispatch => ({
  closeSubmitModal: () => {
    dispatch(closeSubmitModal())
  },
  saveProject: () => {
    dispatch(updateProject())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitModal)
