import bindAll from 'lodash.bindall';
import debounce from 'lodash.debounce';
import defaultsDeep from 'lodash.defaultsdeep';
import upperFirst from 'lodash.upperfirst';
import makeToolboxXML, {BLOCK_VIEW_MODES} from '../lib/make-toolbox-xml';
import PropTypes from 'prop-types';
import React from 'react';
import VMScratchBlocks from '../lib/blocks';
import VM from 'scratch-vm';

import analytics from '../lib/analytics';
import log from '../lib/log.js';
import Prompt from './prompt.jsx';
import BlocksComponent from '../components/blocks/blocks.jsx';
import ExtensionLibrary from './extension-library.jsx';
import extensionData from '../lib/libraries/extensions/index.jsx';
import CustomProcedures from './custom-procedures.jsx';
import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {STAGE_DISPLAY_SIZES} from '../lib/layout-constants';

import {updateCompletion} from '../ninedots/lib/9dots-platform-integration';
import {
    blockNames,
    staticCategories,
    dynamicCategories,
    variableDataCategoryMethodNames,
    normalizeBlockName,
    denormalizeBlockName
} from '../ninedots/lib/block-utilities';

import {connect} from 'react-redux';
import {updateToolbox} from '../reducers/toolbox';
import {activateColorPicker} from '../reducers/color-picker';
import {closeExtensionLibrary, openSoundRecorder, openConnectionModal, openCompletionModal} from '../reducers/modals';
import {activateCustomProcedures, deactivateCustomProcedures} from '../reducers/custom-procedures';
import {setConnectionModalExtensionId} from '../reducers/connection-modal';

import {
    activateTab,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';

import {
    getBlockState,
    getCustomBlockState,
    getCategoryState,
    setBlock,
    setCategory,
    addCustomBlock,
    deleteCustomBlock,
    setCustomBlock
} from '../ninedots/reducers/api-blocks';

import {
    completeChallenge,
    failChallenge
} from '../ninedots/reducers/completion';

import {
    STACK_MODE_STUDENT_COPY,
    POINTER_MODE_DEFAULT,
    POINTER_MODE_LOCK,
    USER_MODE_CREATOR,
    VIEW_MODE_SHOW,
    isPointerConfigMode
} from '../ninedots/reducers/interface-mode';

const addFunctionListener = (object, property, callback) => {
    const oldFn = object[property];
    object[property] = function () {
        const result = oldFn.apply(this, arguments);
        callback.apply(this, result);
        return result;
    };
};

/**
 * Given an XML element, checks if it's a block (to be used for a Blockly /
 * ScratchBlocks flyout), and if so, sets its disabled attribute to true
 * (along with the disabled attribute of any shadow grandchildren).
 * @function disableCategoryXmlBlock
 * @author Julius Diaz Panoriñgan
 * @param {Element} block - an XML element for Blockly/Scratch-Blocks
 */
const disableCategoryXmlBlock = function (block) {
    if (block.tagName === 'block') {
        block.setAttribute('disabled', true);
        block.childNodes.forEach(child => {
            if (child.tagName === 'value') {
                child.childNodes.forEach(grandchild => {
                    if (grandchild.tagName === 'shadow') {
                        grandchild.setAttribute('disabled', true);
                    }
                });
            }
        });
    }
};

class Blocks extends React.Component {
    constructor (props) {
        super(props);
        this.ScratchBlocks = VMScratchBlocks(props.vm);
        bindAll(this, [
            'attachVM',
            'detachVM',
            'handleCategorySelected',
            'handleConnectionModalStart',
            'handleStatusButtonUpdate',
            'handleOpenSoundRecorder',
            'handlePromptStart',
            'handlePromptCallback',
            'handlePromptClose',
            'handleCustomProceduresClose',
            'onScriptGlowOn',
            'onScriptGlowOff',
            'onBlockGlowOn',
            'onBlockGlowOff',
            'handleExtensionAdded',
            'handleBlocksInfoUpdate',
            'onTargetsUpdate',
            'onVisualReport',
            'onWorkspaceUpdate',
            'onWorkspaceMetricsChange',
            'setBlocks',
            'setLocale',
            'generateMyBlocksCategoryXml',
            'generateVariablesCategoryXml',
            'handleBlockEvent',
            'handleChallengeComplete',
            'handleChallengeFail',
            'registerToolboxCallbacks',
            'setDisabledBlockInStack'
        ]);
        this.ScratchBlocks.prompt = this.handlePromptStart;
        this.ScratchBlocks.statusButtonCallback = this.handleConnectionModalStart;
        this.ScratchBlocks.recordSoundCallback = this.handleOpenSoundRecorder;

        this.state = {
            workspaceMetrics: {},
            prompt: null
        };
        this.onTargetsUpdate = debounce(this.onTargetsUpdate, 100);
        this.toolboxUpdateQueue = [];

        props.vm.setExternalBlockListener(this.handleBlockEvent);
    }
    componentDidMount () {
        this.ScratchBlocks.FieldColourSlider.activateEyedropper_ = this.props.onActivateColorPicker;
        this.ScratchBlocks.Procedures.externalProcedureDefCallback = this.props.onActivateCustomProcedures;

        const workspaceConfig = defaultsDeep({},
            Blocks.defaultOptions,
            this.props.options,
            {rtl: this.props.isRtl, toolbox: this.props.toolboxXML}
        );
        this.workspace = this.ScratchBlocks.inject(this.blocks, workspaceConfig);

        this.registerToolboxCallbacks();
        
        // we actually never want the workspace to enable "refresh toolbox" - this basically re-renders the
        // entire toolbox every time we reset the workspace.  We call updateToolbox as a part of
        // componentDidUpdate so the toolbox will still correctly be updated
        this.setToolboxRefreshEnabled = this.workspace.setToolboxRefreshEnabled.bind(this.workspace);
        this.workspace.setToolboxRefreshEnabled = () => {
            this.setToolboxRefreshEnabled(false);
        };

        // @todo change this when blockly supports UI events
        addFunctionListener(this.workspace, 'translate', this.onWorkspaceMetricsChange);
        addFunctionListener(this.workspace, 'zoom', this.onWorkspaceMetricsChange);

        this.attachVM();
        // Only update blocks/vm locale when visible to avoid sizing issues
        // If locale changes while not visible it will get handled in didUpdate
        if (this.props.isVisible) {
            this.setLocale();
        }

        analytics.pageview('/editors/blocks');
    }
    shouldComponentUpdate (nextProps, nextState) {
        return (
            this.state.prompt !== nextState.prompt ||
            this.props.isVisible !== nextProps.isVisible ||
            this.props.toolboxXML !== nextProps.toolboxXML ||
            this.props.extensionLibraryVisible !== nextProps.extensionLibraryVisible ||
            this.props.customProceduresVisible !== nextProps.customProceduresVisible ||
            this.props.locale !== nextProps.locale ||
            this.props.anyModalVisible !== nextProps.anyModalVisible ||
            this.props.stageSize !== nextProps.stageSize ||
            this.props.toolboxDisplayMode !== nextProps.toolboxDisplayMode ||
            this.props.apiBlocks !== nextProps.apiBlocks
        );
    }
    componentDidUpdate (prevProps) {
        // If any modals are open, call hideChaff to close z-indexed field editors
        if (this.props.anyModalVisible && !prevProps.anyModalVisible) {
            this.ScratchBlocks.hideChaff();
        }

        // If we're switching between a configuration and default pointer mode,
        // or the api blocks are updating in redux state,
        // update the toolbox!
        if (prevProps.toolboxDisplayMode !== this.props.toolboxDisplayMode ||
            prevProps.apiBlocks !== this.props.apiBlocks) {

            // to be safe, check for an editing target before updating
            if (this.props.vm.editingTarget) {
                const target = this.props.vm.editingTarget;
                const toolboxXML = makeToolboxXML(
                    target.isStage,
                    target.id,
                    this.props.vm.runtime.getBlocksXML(),
                    this.props.apiBlocks,
                    this.props.toolboxDisplayMode
                );
                this.props.updateToolboxState(toolboxXML);

                // if there's an api change to the custom categories,
                // the toolboxXML won't change,
                // so manually trigger a toolbox refresh
                if (toolboxXML === this.props.toolboxXML) {
                    this.updateToolboxNextFrame();
                }
            }
        }

        if (prevProps.toolboxXML !== this.props.toolboxXML) {
            // rather than update the toolbox "sync" -- update it in the next frame
            this.updateToolboxNextFrame();
        }
        if (this.props.isVisible === prevProps.isVisible) {
            if (this.props.stageSize !== prevProps.stageSize) {
                // force workspace to redraw for the new stage size
                window.dispatchEvent(new Event('resize'));
            }
            return;
        }
        // @todo hack to resize blockly manually in case resize happened while hidden
        // @todo hack to reload the workspace due to gui bug #413
        if (this.props.isVisible) { // Scripts tab
            this.workspace.setVisible(true);
            if (prevProps.locale !== this.props.locale || this.props.locale !== this.props.vm.getLocale()) {
                // call setLocale if the locale has changed, or changed while the blocks were hidden.
                // vm.getLocale() will be out of sync if locale was changed while not visible
                this.setLocale();
            } else {
                this.props.vm.refreshWorkspace();
                this.updateToolbox();
            }

            window.dispatchEvent(new Event('resize'));
        } else {
            this.workspace.setVisible(false);
        }
    }
    componentWillUnmount () {
        this.detachVM();
        this.workspace.dispose();
        clearTimeout(this.toolboxUpdateTimeout);
    }

    setLocale () {
        this.workspace.getFlyout().setRecyclingEnabled(false);
        this.ScratchBlocks.ScratchMsgs.setLocale(this.props.locale);
        this.props.vm.setLocale(this.props.locale, this.props.messages)
            .then(() => {
                this.props.vm.refreshWorkspace();
                this.updateToolbox();
                this.workspace.getFlyout().setRecyclingEnabled(true);
            });
    }

    updateToolbox () {
        this.toolboxUpdateTimeout = false;

        const categoryId = this.workspace.toolbox_.getSelectedCategoryId();
        const offset = this.workspace.toolbox_.getCategoryScrollOffset();
        this.workspace.updateToolbox(this.props.toolboxXML);
        // In order to catch any changes that mutate the toolbox during "normal runtime"
        // (variable changes/etc), re-enable toolbox refresh.
        // Using the setter function will rerender the entire toolbox which we just rendered.
        this.workspace.toolboxRefreshEnabled_ = true;

        const currentCategoryPos = this.workspace.toolbox_.getCategoryPositionById(categoryId);
        const currentCategoryLen = this.workspace.toolbox_.getCategoryLengthById(categoryId);
        if (offset < currentCategoryLen) {
            this.workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos + offset);
        } else {
            this.workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos);
        }

        const queue = this.toolboxUpdateQueue;
        this.toolboxUpdateQueue = [];
        queue.forEach(fn => fn());
    }

    /**
     * This updates the toolbox in the next frame.
     * It's just factoring out code from gnarf (Corey Frang),
     * which is now used in multiple places.
     * @function updateToolboxNextFrame
     * @author Julius Diaz Panoriñgan
     */
    updateToolboxNextFrame () {
        // rather than update the toolbox "sync" -- update it in the next frame
        clearTimeout(this.toolboxUpdateTimeout);
        this.toolboxUpdateTimeout = setTimeout(() => {
            this.updateToolbox();
        }, 0);
    }

    withToolboxUpdates (fn) {
        // if there is a queued toolbox update, we need to wait
        if (this.toolboxUpdateTimeout) {
            this.toolboxUpdateQueue.push(fn);
        } else {
            fn();
        }
    }

    attachVM () {
        this.workspace.addChangeListener(this.props.vm.blockListener);
        this.flyoutWorkspace = this.workspace
            .getFlyout()
            .getWorkspace();
        this.flyoutWorkspace.addChangeListener(this.props.vm.flyoutBlockListener);
        this.flyoutWorkspace.addChangeListener(this.props.vm.monitorBlockListener);
        this.props.vm.addListener('SCRIPT_GLOW_ON', this.onScriptGlowOn);
        this.props.vm.addListener('SCRIPT_GLOW_OFF', this.onScriptGlowOff);
        this.props.vm.addListener('BLOCK_GLOW_ON', this.onBlockGlowOn);
        this.props.vm.addListener('BLOCK_GLOW_OFF', this.onBlockGlowOff);
        this.props.vm.addListener('VISUAL_REPORT', this.onVisualReport);
        this.props.vm.addListener('workspaceUpdate', this.onWorkspaceUpdate);
        this.props.vm.addListener('targetsUpdate', this.onTargetsUpdate);
        this.props.vm.addListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.addListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.addListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.addListener('PERIPHERAL_DISCONNECT_ERROR', this.handleStatusButtonUpdate);
        this.props.vm.runtime.addListener('COMPLETE', this.handleChallengeComplete);
        this.props.vm.runtime.addListener('FAIL', this.handleChallengeFail);
    }
    detachVM () {
        this.props.vm.removeListener('SCRIPT_GLOW_ON', this.onScriptGlowOn);
        this.props.vm.removeListener('SCRIPT_GLOW_OFF', this.onScriptGlowOff);
        this.props.vm.removeListener('BLOCK_GLOW_ON', this.onBlockGlowOn);
        this.props.vm.removeListener('BLOCK_GLOW_OFF', this.onBlockGlowOff);
        this.props.vm.removeListener('VISUAL_REPORT', this.onVisualReport);
        this.props.vm.removeListener('workspaceUpdate', this.onWorkspaceUpdate);
        this.props.vm.removeListener('targetsUpdate', this.onTargetsUpdate);
        this.props.vm.removeListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.removeListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECT_ERROR', this.handleStatusButtonUpdate);
        this.props.vm.runtime.removeListener('COMPLETE', this.handleChallengeComplete);
        this.props.vm.runtime.removeListener('FAIL', this.handleChallengeFail);
    }

    updateToolboxBlockValue (id, value) {
        this.withToolboxUpdates(() => {
            const block = this.workspace
                .getFlyout()
                .getWorkspace()
                .getBlockById(id);
            if (block) {
                block.inputList[0].fieldRow[0].setValue(value);
            }
        });
    }

    onTargetsUpdate () {
        if (this.props.vm.editingTarget) {
            ['glide', 'move', 'set'].forEach(prefix => {
                this.updateToolboxBlockValue(`${prefix}x`, Math.round(this.props.vm.editingTarget.x).toString());
                this.updateToolboxBlockValue(`${prefix}y`, Math.round(this.props.vm.editingTarget.y).toString());
            });
        }
    }
    onWorkspaceMetricsChange () {
        const target = this.props.vm.editingTarget;
        if (target && target.id) {
            const workspaceMetrics = Object.assign({}, this.state.workspaceMetrics, {
                [target.id]: {
                    scrollX: this.workspace.scrollX,
                    scrollY: this.workspace.scrollY,
                    scale: this.workspace.scale
                }
            });
            this.setState({workspaceMetrics});
        }
    }
    onScriptGlowOn (data) {
        this.workspace.glowStack(data.id, true);
    }
    onScriptGlowOff (data) {
        this.workspace.glowStack(data.id, false);
    }
    onBlockGlowOn (data) {
        this.workspace.glowBlock(data.id, true);
    }
    onBlockGlowOff (data) {
        this.workspace.glowBlock(data.id, false);
    }
    onVisualReport (data) {
        this.workspace.reportValue(data.id, data.value);
    }
    onWorkspaceUpdate (data) {
        // When we change sprites, update the toolbox to have the new sprite's blocks
        if (this.props.vm.editingTarget) {
            const target = this.props.vm.editingTarget;
            const dynamicBlocksXML = this.props.vm.runtime.getBlocksXML();
            const toolboxXML = makeToolboxXML(
                target.isStage,
                target.id,
                dynamicBlocksXML,
                this.props.apiBlocks,
                this.props.toolboxDisplayMode
            );
            this.props.updateToolboxState(toolboxXML);

            // if all these blocks are disabled
            //   - motion: xposition, yposition, direction
            //   - looks: switchcostumeto, costumenumbername, size
            //   - sound: playuntildone, play
            // the static toolboxXML is not changed when targets changed,
            // so manually trigger a toolbox refresh
            // to make sure the dynamic blocks are updated
            if (toolboxXML === this.props.toolboxXML) {
                this.updateToolboxNextFrame();
            }
        }

        if (this.props.vm.editingTarget && !this.state.workspaceMetrics[this.props.vm.editingTarget.id]) {
            this.onWorkspaceMetricsChange();
        }

        // Remove and reattach the workspace listener (but allow flyout events)
        this.workspace.removeChangeListener(this.props.vm.blockListener);
        const dom = this.ScratchBlocks.Xml.textToDom(data.xml);
        try {
            this.ScratchBlocks.Xml.clearWorkspaceAndLoadFromXml(dom, this.workspace);
        } catch (error) {
            // The workspace is likely incomplete. What did update should be
            // functional.
            //
            // Instead of throwing the error, by logging it and continuing as
            // normal lets the other workspace update processes complete in the
            // gui and vm, which lets the vm run even if the workspace is
            // incomplete. Throwing the error would keep things like setting the
            // correct editing target from happening which can interfere with
            // some blocks and processes in the vm.
            error.message = `Workspace Update Error: ${error.message}`;
            log.error(error);
        }
        this.workspace.addChangeListener(this.props.vm.blockListener);

        if (this.props.vm.editingTarget && this.state.workspaceMetrics[this.props.vm.editingTarget.id]) {
            const {scrollX, scrollY, scale} = this.state.workspaceMetrics[this.props.vm.editingTarget.id];
            this.workspace.scrollX = scrollX;
            this.workspace.scrollY = scrollY;
            this.workspace.scale = scale;
            this.workspace.resize();
        }

        // Clear the undo state of the workspace since this is a
        // fresh workspace and we don't want any changes made to another sprites
        // workspace to be 'undone' here.
        this.workspace.clearUndo();
    }
    handleExtensionAdded (blocksInfo) {
        // select JSON from each block info object then reject the pseudo-blocks which don't have JSON, like separators
        // this actually defines blocks and MUST run regardless of the UI state
        this.ScratchBlocks.defineBlocksWithJsonArray(blocksInfo.map(blockInfo => blockInfo.json).filter(x => x));

        // update the toolbox view: this can be skipped if we're not looking at a target, etc.
        const runtime = this.props.vm.runtime;
        const target = runtime.getEditingTarget() || runtime.getTargetForStage();
        if (target) {
            const dynamicBlocksXML = runtime.getBlocksXML();
            const toolboxXML = makeToolboxXML(
                target.isStage,
                target.id,
                dynamicBlocksXML,
                this.props.apiBlocks,
                this.props.toolboxDisplayMode
            );
            this.props.updateToolboxState(toolboxXML);
        }
    }
    handleBlocksInfoUpdate (blocksInfo) {
        // @todo Later we should replace this to avoid all the warnings from redefining blocks.
        this.handleExtensionAdded(blocksInfo);
    }
    handleCategorySelected (categoryId) {
        const extension = extensionData.find(ext => ext.extensionId === categoryId);
        if (extension && extension.launchPeripheralConnectionFlow) {
            this.handleConnectionModalStart(categoryId);
        }

        this.withToolboxUpdates(() => {
            this.workspace.toolbox_.setSelectedCategoryById(categoryId);
        });
    }
    setBlocks (blocks) {
        this.blocks = blocks;
    }
    handlePromptStart (message, defaultValue, callback, optTitle, optVarType) {
        const p = {prompt: {callback, message, defaultValue}};
        p.prompt.title = optTitle ? optTitle :
            this.ScratchBlocks.Msg.VARIABLE_MODAL_TITLE;
        p.prompt.varType = typeof optVarType === 'string' ?
            optVarType : this.ScratchBlocks.SCALAR_VARIABLE_TYPE;
        p.prompt.showVariableOptions = // This flag means that we should show variable/list options about scope
            optVarType !== this.ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE &&
            p.prompt.title !== this.ScratchBlocks.Msg.RENAME_VARIABLE_MODAL_TITLE &&
            p.prompt.title !== this.ScratchBlocks.Msg.RENAME_LIST_MODAL_TITLE;
        this.setState(p);
    }
    handleConnectionModalStart (extensionId) {
        this.props.onOpenConnectionModal(extensionId);
    }
    handleStatusButtonUpdate () {
        this.ScratchBlocks.refreshStatusButtons(this.workspace);
    }
    handleOpenSoundRecorder () {
        this.props.onOpenSoundRecorder();
    }

    /*
     * Pass along information about proposed name and variable options (scope and isCloud)
     * and additional potentially conflicting variable names from the VM
     * to the variable validation prompt callback used in scratch-blocks.
     */
    handlePromptCallback (input, variableOptions) {
        this.state.prompt.callback(
            input,
            this.props.vm.runtime.getAllVarNamesOfType(this.state.prompt.varType),
            variableOptions);
        this.handlePromptClose();
    }
    handlePromptClose () {
        this.setState({prompt: null});
    }
    handleCustomProceduresClose (data) {
        this.props.onRequestCloseCustomProcedures(data);
        const ws = this.workspace;
        ws.refreshToolboxSelection_();
        ws.toolbox_.scrollToCategoryById('myBlocks');
    }

    /**
     * This function intercepts events from scratch-blocks.
     * Currently forwards to handlers that handle click and variable events.
     * @function handleBlockEvent
     * @author Julius Diaz Panoriñgan
     * @param {!Blockly.Event} e - a Blockly event
     * @returns {boolean} indicates whether the vm should continue processing the event
     */
    handleBlockEvent (e) {

        if (e.element === 'click' || e.element === 'stackclick') {
            return this.handleBlockClick(e);
        }

        if (['var_create', 'var_rename', 'var_delete'].includes(e.type)) {
            return this.handleVariableEvent(e);
        }

        return true;
    }
    
    /**
     * This function handles clicks from scratch-blocks.
     * Currently, it toggles workspace blocks' read-only status when in pointer
     * lock mode, and toggles toolbox/flyout blocks' hidden status (for
     * students) when in pointer hide or lock mode.
     * @function handleBlockClick
     * @author Julius Diaz Panoriñgan
     * @param {!Blockly.Event} e - a Blockly event
     * @returns {boolean} indicates whether the vm should continue processing the event
     */
    handleBlockClick (e) {
        // prevent scripts from being executed unless pointer is in default mode
        if (this.props.isPointerConfigMode && e.element === 'stackclick') return false;

        // set workspace blocks to readonly if pointer is in lock mode
        if (this.props.isPointerLockMode && e.element === 'click' && e.listenerType === 'WORKSPACE_BLOCK') {
            // toggle in VM and get resulting value
            const isEditable = !this.props.vm.editingTarget.blocks.toggleReadOnly(e.blockId);

            // toggle deletability, editability, movability in scratch-blocks
            const clickedBlock = this.workspace.getBlockById(e.blockId);
            if (clickedBlock) {
                clickedBlock.setDeletable(isEditable);
                clickedBlock.setEditable(isEditable);
                clickedBlock.setMovable(isEditable);
                clickedBlock.getChildren().forEach(childBlock => {
                    if (childBlock.isShadow()) {
                        // no need to set deletable or movable on a shadow block in Scratch-Blocks
                        childBlock.setEditable(isEditable);
                    }
                });
            /** @todo assert block existence? */
            // } else {
            //     console.error(`could not get block from id ${e.blockId}`);
            }
        }

        // set flyout/toolbox blocks to readonly if in hide/lock mode
        if (this.props.isPointerConfigMode && e.element === 'click' && e.listenerType === 'FLYOUT_BLOCK') {

            const {blockId} = e;
            const block = this.flyoutWorkspace.getBlockById(blockId);

            // current assumption: asserting non-null block is unnecessary
            if (['data_variable', 'data_listcontents'].includes(block.type)) {
                /** @todo also handle custom procedure block clicks, i.e. 'procedures_call'*/

                const wasEnabled = getCustomBlockState(this.props.apiBlocks, 'variables', blockId);
                // handle both ScratchBlocks and global api block state
                this.setDisabledBlockInStack(block, wasEnabled);
                this.props.setCustomBlock('variables', blockId, !wasEnabled);
            } else {
                const blockName = normalizeBlockName(blockId, this.props.vm.editingTarget.id);
                const wasEnabled = getBlockState(this.props.apiBlocks, blockName);
                // handle both ScratchBlocks and global api block state
                this.setDisabledBlockInStack(block, wasEnabled);
                this.props.setBlock(blockName, !wasEnabled);
    
                /** @todo also disable corresponding stage-specific blocks when appropriate */
            }

        }

        return true;
    }

    /**
     * Handles variable events from scratch-blocks.
     * Currently, when variables are created/deleted, it modifies redux state to
     * allow/stop tracking the enabled status of that variable.
     * @todo update this to track human-readable variable names, not just varIds
     * @function handleVariableEvent
     * @author Julius Diaz Panoriñgan
     * @param {!Blockly.Event} e - a Blockly event
     * @returns {boolean} indicates whether the vm should continue processing the event
     */
    handleVariableEvent (e) {
        switch (e.type) {
        case 'var_create':
            this.props.addCustomBlock('variables', e.varId);
            break;
        case 'var_rename':
            break;
        case 'var_delete':
            this.props.deleteCustomBlock('variables', e.varId);
            break;
        }

        return true;
    }

    /**
     * Register callbacks for (a) generating dynamic category xml, and (b)
     * toggling categories of blocks via blocks in Scratch-Blocks/Blockly.
     * @function registerToolboxCallbacks
     * @author Julius Diaz Panoriñgan
     */
    registerToolboxCallbacks () {
        // register toolbox category (generation) callbacks
        this.workspace.registerToolboxCategoryCallback(
            this.ScratchBlocks.VARIABLE_CATEGORY_NAME,
            this.generateVariablesCategoryXml
        );
        this.workspace.registerToolboxCategoryCallback(
            this.ScratchBlocks.PROCEDURE_CATEGORY_NAME,
            this.generateMyBlocksCategoryXml
        );

        // register buttons to toggle static toolbox categories
        staticCategories.forEach(categoryName => {
            const upperCaseName = upperFirst(categoryName);

            // note that Scratch-Blocks/Blockly tracks disabled state,
            // and we track enabled state,which is the opposite
            this.workspace.registerButtonCallback(`enable${upperCaseName}`, () => {
                blockNames[categoryName]
                    .forEach(blockName => {
                        const scratchBlockName = denormalizeBlockName(blockName, this.props.vm.editingTarget.id);
                        const scratchBlock = this.flyoutWorkspace.getBlockById(scratchBlockName);
                        this.setDisabledBlockInStack(scratchBlock, false);
                    });
                this.props.setCategory(categoryName, true);
            });
            this.workspace.registerButtonCallback(`disable${upperCaseName}`, () => {
                blockNames[categoryName]
                    .forEach(blockName => {
                        const scratchBlockName = denormalizeBlockName(blockName, this.props.vm.editingTarget.id);
                        const scratchBlock = this.flyoutWorkspace.getBlockById(scratchBlockName);
                        this.setDisabledBlockInStack(scratchBlock, true);
                    });
                this.props.setCategory(categoryName, false);
            });
        });

        // register buttons to toggle dynamic toolbox categories
        dynamicCategories.forEach(categoryName => {
            const upperCaseName = upperFirst(categoryName);

            this.workspace.registerButtonCallback(`enable${upperCaseName}`, () => {
                this.props.setCategory(categoryName, true);
            });
            this.workspace.registerButtonCallback(`disable${upperCaseName}`, () => {
                this.props.setCategory(categoryName, false);
            });
        });

        // register a null callback
        this.workspace.registerButtonCallback('nullCallback', () => {});

        // register callbacks for enabling/disabling specific buttons
        /** @todo add callback for enabling/disabling "Make a Block" */
        this.workspace.registerButtonCallback('enableMakeVariable', () => {
            this.props.setBlock('button_make_a_variable', true);
        });
        this.workspace.registerButtonCallback('disableMakeVariable', () => {
            this.props.setBlock('button_make_a_variable', false);
        });
        this.workspace.registerButtonCallback('enableMakeList', () => {
            this.props.setBlock('button_make_a_list', true);
        });
        this.workspace.registerButtonCallback('disableMakeList', () => {
            this.props.setBlock('button_make_a_list', false);
        });
    }

    /**
     * Sets a Scratch-Blocks/Blockly block to disabled or not
     * (along with its shadow children).
     * @function setDisabledBlockInStack
     * @author Julius Diaz Panoriñgan
     * @param {Blockly.Block} block - a Scratch-Blocks/Blockly block
     * @param {boolean} disabled - the disabled state to set
     */
    setDisabledBlockInStack (block, disabled) {
        if (block) { // just being ultra-safe
            block.setDisabled(disabled);
            block.getChildren().forEach(childBlock => {
                if (childBlock.isShadow()) {
                    childBlock.setDisabled(disabled);
                }
            });
        }
    }

    /**
     * Callback to be passed to Scratch-Blocks/Blockly, which generates the
     * xml for the dynamic variables category.
     * If we're in a configuration mode, renders xml with a status message
     * (enabled / partially enabled / disabled) and a button to enable/disable
     * the entire category.
     * Else, renders the XML for the blocks, and buttons to create new
     * variables/lists (with their corresponding blocks). (The logic is borrowed
     * from scratch-blocks, in core/data_category.js, from the
     * Blockly.DataCategory function.)
     * *IMP'T NOTE*
     * When a variable is created, this function is called (by
     * Blockly/Scratch-Blocks, I assume) before the global redux state is
     * updated (and then called again after global state is updated). In this
     * case, we expect exactly one custom block to not be in state.
     * See the commented-out usage of `detectedBlockNotInState`.
     * @function generateVariablesCategoryXml
     * @author Julius Diaz Panoriñgan
     * @param {object} workspace - A Scratch-Blocks/Blockly workspace
     * @returns {string} XML for the dynamic variables category
     */
    generateVariablesCategoryXml (workspace) {
        const xmlList = [];

        // below, note the use of strict equality; getCategoryState may return undefined
        const categoryEnabled = getCategoryState(this.props.apiBlocks, 'variables');

        const isConfigMode = this.props.toolboxDisplayMode === BLOCK_VIEW_MODES.CONFIG;
        const isShowMode = this.props.toolboxDisplayMode === BLOCK_VIEW_MODES.SHOW;

        const {DataCategory, VariableModel} = this.ScratchBlocks;
        const enabledStaticBlocks = this.props.apiBlocks.variables.staticBlocks;
        const enabledDynamicBlocks = this.props.apiBlocks.variables.dynamicBlocks;

        let firstVariable; // declared here for use by addBlockXmlConditionally()

        /*
          This variable can be used for debugging/asserting that at most one
          variable is not defined in state; search for its commented-out uses in
          this function.
          
        */
        // let detectedBlockNotInState = false;

        /**
         * Inner utility used to add individual flyout blocks.
         * @function addBlockXmlConditionally
         * @author Julius Diaz Panoriñgan
         * @param {string} blockName - name of the relevant block
         */
        const addBlockXmlConditionally = function (blockName) {
            const isBlockEnabled = enabledStaticBlocks[blockName];
            if (isBlockEnabled || isShowMode || isConfigMode) {
                DataCategory[variableDataCategoryMethodNames[blockName]](xmlList, firstVariable);
                if (!isBlockEnabled) {
                    disableCategoryXmlBlock(xmlList[xmlList.length - 1]);
                }
            }
        };

        // If in config mode, we render the appropriate 'show all'/'hide all' blocks
        if (isConfigMode) {
            if (categoryEnabled === false) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<hide callbackKey="enableVariables" />'
                ));
                
            } else {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<show callbackKey="disableVariables" />'
                ));
            }
        }

        // render blocks and buttons for variables and lists

        let variableModelList = workspace.getVariablesOfType('');
        variableModelList.sort(VariableModel.compareByName);

        // add variable button
        const isMakeVariableEnabled = enabledStaticBlocks.button_make_a_variable;
        if (isConfigMode) {
            if (isMakeVariableEnabled) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<button text="Make a Variable Enabled" callbackKey="disableMakeVariable" />'
                ));
            } else {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<button text="Make a Variable Disabled" callbackKey="enableMakeVariable" />'
                ));
            }
        } else if (isShowMode || isMakeVariableEnabled) {
            DataCategory.addCreateButton(xmlList, workspace, 'VARIABLE');
        }

        // variable blocks
        for (let i = 0; i < variableModelList.length; i++) {
            // if custom block not in state (should be only one),
            // skip that variable id
            // 'cause this function will be called again right after the block
            // is added to state
            if (!enabledDynamicBlocks[variableModelList[i].id_]) {
                // if (detectedBlockNotInState) {
                //     throw new Error("more than one custom block not detected in state");
                // } else {
                //     detectedBlockNotInState = true;
                // }
                continue;
            }
            
            const isBlockEnabled = enabledDynamicBlocks[variableModelList[i].id_].isEnabled;
            if (isBlockEnabled || isShowMode || isConfigMode) {
                DataCategory.addDataVariable(xmlList, variableModelList[i]);
                if (!isBlockEnabled) {
                    disableCategoryXmlBlock(xmlList[xmlList.length - 1]);
                }
            }
        }

        // variable modification blocks
        if (variableModelList.length > 0) {
            if (xmlList.length) { // make sure this isn't the first item
                xmlList[xmlList.length - 1].setAttribute('gap', 24);
            }
            firstVariable = variableModelList[0];
            addBlockXmlConditionally('data_setvariableto');
            addBlockXmlConditionally('data_changevariableby');
            addBlockXmlConditionally('data_showvariable');
            addBlockXmlConditionally('data_hidevariable');
        }
        
        // add list button
        const isMakeListEnabled = enabledStaticBlocks.button_make_a_list;
        if (isConfigMode) {
            if (isMakeListEnabled) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<button text="Make a List Enabled" callbackKey="disableMakeList" />'
                ));
            } else {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<button text="Make a List Disabled" callbackKey="enableMakeList" />'
                ));
            }
        } else if (isShowMode || isMakeListEnabled) {
            DataCategory.addCreateButton(xmlList, workspace, 'LIST');
        }

        variableModelList = workspace.getVariablesOfType(this.ScratchBlocks.LIST_VARIABLE_TYPE);
        variableModelList.sort(VariableModel.compareByName);

        // list blocks
        for (let i = 0; i < variableModelList.length; i++) {
            // if custom block not in state (should be only one),
            // skip that variable id
            // 'cause this function will be called again right after the block
            // is added to state
            if (!enabledDynamicBlocks[variableModelList[i].id_]) {
                // if (detectedBlockNotInState) {
                //     throw new Error("more than one custom block not detected in state");
                // } else {
                //     detectedBlockNotInState = true;
                // }
                continue;
            }

            const isBlockEnabled = enabledDynamicBlocks[variableModelList[i].id_].isEnabled;
            if (isBlockEnabled || isShowMode || isConfigMode) {
                DataCategory.addDataList(xmlList, variableModelList[i]);
                if (!isBlockEnabled) {
                    disableCategoryXmlBlock(xmlList[xmlList.length - 1]);
                }
            }
        }

        // list modification blocks
        if (variableModelList.length > 0) {
            if (xmlList.length) { // make sure this isn't the first item
                xmlList[xmlList.length - 1].setAttribute('gap', 24);
            }
            firstVariable = variableModelList[0];

            addBlockXmlConditionally('data_addtolist');
            DataCategory.addSep(xmlList);
            addBlockXmlConditionally('data_deleteoflist');
            addBlockXmlConditionally('data_deletealloflist');
            addBlockXmlConditionally('data_insertatlist');
            addBlockXmlConditionally('data_replaceitemoflist');
            DataCategory.addSep(xmlList);
            addBlockXmlConditionally('data_itemoflist');
            addBlockXmlConditionally('data_itemnumoflist');
            addBlockXmlConditionally('data_lengthoflist');
            addBlockXmlConditionally('data_listcontainsitem');
            DataCategory.addSep(xmlList);
            addBlockXmlConditionally('data_showlist');
            addBlockXmlConditionally('data_hidelist');
        }

        // info message if list empty
        if (xmlList.every(element => element.tagName === 'sep')) {
            return [
                this.ScratchBlocks.Xml.textToDom(
                    '<label text="No variables blocks currently available" />'
                )
            ];
        }

        return xmlList;
    }

    /**
     * Callback to be passed to Scratch-Blocks/Blockly, which generates the
     * xml for the dynamic my blocks category.
     * If we're in a configuration mode, renders xml with a status message
     * (enabled / partially enabled / disabled) and a button to enable/disable
     * the entire category.
     * Else, renders the XML for the blocks, and buttons to create new
     * procedures (with their corresponding blocks). (The logic is borrowed
     * from scratch-blocks, in core/procedures.js, from the
     * Blockly.Procedures.flyoutCategory function.)
     * @function generateMyBlocksCategoryXml
     * @author Julius Diaz Panoriñgan
     * @param {object} workspace - A Scratch-Blocks/Blockly workspace
     * @returns {string} XML for the dynamic variables category
     */
    generateMyBlocksCategoryXml (workspace) {
        const xmlList = [];

        // below, note the use of strict equality; getCategoryState may return undefined
        const categoryEnabled = getCategoryState(this.props.apiBlocks, 'myBlocks');

        const isConfigMode = this.props.toolboxDisplayMode === BLOCK_VIEW_MODES.CONFIG;
        const isShowMode = this.props.toolboxDisplayMode === BLOCK_VIEW_MODES.SHOW;

        // old code for showing status labels
        /* //
        if (isConfigMode || isShowMode) {
            if (categoryEnabled === true) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<label text="My Blocks Enabled" />'
                ));
            } else if (categoryEnabled === false) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<label text="My Blocks Disabled" />'
                ));
            }
        }
        // */

        // If in config mode, we render the appropriate 'show'/'hide' blocks */
        // and return early
        if (isConfigMode) {
            if (categoryEnabled === true) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<show callbackKey="disableMyBlocks" />'
                ));
            } else if (categoryEnabled === false) {
                xmlList.push(this.ScratchBlocks.Xml.textToDom(
                    '<hide callbackKey="enableMyBlocks" />'
                ));
            }

            return xmlList;
        }

        const {Procedures} = this.ScratchBlocks;
        const showDisabled = categoryEnabled === false && isShowMode;

        // add procedure button (possibly a dummy if showing disabled)
        if (this.props.apiBlocks.myBlocks.staticBlocks.button_make_a_block) {
            Procedures.addCreateButton_(workspace, xmlList);
        } else if (showDisabled) {
            xmlList.push(this.ScratchBlocks.Xml.textToDom(
                '<button text="Make a Block" callbackKey="nullCallback" />'
            ));
        }

        // call blocks for each procedure
        /** @todo enable/disable defined procedures? */
        let mutations = Procedures.allProcedureMutations(workspace);
        mutations = Procedures.sortProcedureMutations_(mutations);
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            // <block type="procedures_call">
            //   <mutation ...></mutation>
            // </block>
            const block = Procedures.createGoogDomBlock(); // var block = goog.dom.createDom('block');
            block.setAttribute('type', 'procedures_call');
            block.setAttribute('gap', 16);
            block.appendChild(mutation);
            xmlList.push(block);
        }

        /** @todo disable on a block-by-block basis */
        if (showDisabled) {
            xmlList.forEach(element => {
                if (element.tagName === 'block') {
                    element.setAttribute('disabled', true);
                }
            });
        }

        return xmlList;
    }

    /**
     * This callback, triggered by the execution of a completion block,
     * displays a modal with information on the completion of the challenge.
     * For challenge creators, it also enables the 'Done' button at the end of
     * the challenge creation flow.
     * If the Stack project is of type 'STUDENT_COPY', it also sends an update
     * to the 9 Dots platform indicating successful challenge completion.
     * @function handleChallengeComplete
     * @author Julius Diaz Panoriñgan
     * @param {string} message - a message to the student from the challenge creator
     */
    handleChallengeComplete (message) {
        this.props.completeChallenge(message);
        if (this.props.isStudent) {
            updateCompletion(this.props.reduxProjectId);
        }
    }

    /**
     * This callback, triggered by the execution of a fail block, currently
     * displays a modal with information on the completion of the challenge.
     * It will later communicate challenge failure to the challenge creator.
     * @function handleChallengeFail
     * @author Julius Diaz Panoriñgan
     * @param {string} message - a message to the student from the challenge creator
     */
    handleChallengeFail (message) {
        this.props.failChallenge(message);
    }

    render () {
        /* eslint-disable no-unused-vars */
        const {
            anyModalVisible,
            customProceduresVisible,
            extensionLibraryVisible,
            options,
            stageSize,
            vm,
            isRtl,
            isVisible,
            onActivateColorPicker,
            onOpenConnectionModal,
            onOpenSoundRecorder,
            updateToolboxState,
            onActivateCustomProcedures,
            onRequestCloseExtensionLibrary,
            onRequestCloseCustomProcedures,
            toolboxXML,
            // Stack / 9 Dots
            addCustomBlock: addCustomBlockProp,
            apiBlocks,
            completeChallenge: completeChallengeProp,
            deleteCustomBlock: deleteCustomBlockProp,
            failChallenge: failChallengeProp,
            isPointerDefaultMode,
            isPointerConfigMode: isPointerConfigModeProp,
            isPointerLockMode,
            isStudent,
            reduxProjectId,
            setBlock: setBlockProp,
            setCategory: setCategoryProp,
            setCustomBlock: setCustomBlockProp,
            toolboxDisplayMode,
            ...props
        } = this.props;
        /* eslint-enable no-unused-vars */
        return (
            <div>
                <BlocksComponent
                    componentRef={this.setBlocks}
                    {...props}
                />
                {this.state.prompt ? (
                    <Prompt
                        isStage={vm.runtime.getEditingTarget().isStage}
                        label={this.state.prompt.message}
                        placeholder={this.state.prompt.defaultValue}
                        showVariableOptions={this.state.prompt.showVariableOptions}
                        title={this.state.prompt.title}
                        onCancel={this.handlePromptClose}
                        onOk={this.handlePromptCallback}
                    />
                ) : null}
                {extensionLibraryVisible ? (
                    <ExtensionLibrary
                        vm={vm}
                        onCategorySelected={this.handleCategorySelected}
                        onRequestClose={onRequestCloseExtensionLibrary}
                    />
                ) : null}
                {customProceduresVisible ? (
                    <CustomProcedures
                        options={{
                            media: options.media
                        }}
                        onRequestClose={this.handleCustomProceduresClose}
                    />
                ) : null}
            </div>
        );
    }
}

Blocks.propTypes = {
    addCustomBlock: PropTypes.func.isRequired,
    anyModalVisible: PropTypes.bool,
    apiBlocks: PropTypes.objectOf(PropTypes.object).isRequired,
    completeChallenge: PropTypes.func.isRequired,
    customProceduresVisible: PropTypes.bool,
    deleteCustomBlock: PropTypes.func.isRequired,
    extensionLibraryVisible: PropTypes.bool,
    failChallenge: PropTypes.func.isRequired,
    isPointerConfigMode: PropTypes.bool.isRequired,
    isPointerDefaultMode: PropTypes.bool.isRequired,
    isPointerLockMode: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool,
    isStudent: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool,
    locale: PropTypes.string,
    messages: PropTypes.objectOf(PropTypes.string),
    onActivateColorPicker: PropTypes.func,
    onActivateCustomProcedures: PropTypes.func,
    onOpenConnectionModal: PropTypes.func,
    onOpenSoundRecorder: PropTypes.func,
    onRequestCloseCustomProcedures: PropTypes.func,
    onRequestCloseExtensionLibrary: PropTypes.func,
    options: PropTypes.shape({
        media: PropTypes.string,
        zoom: PropTypes.shape({
            controls: PropTypes.bool,
            wheel: PropTypes.bool,
            startScale: PropTypes.number
        }),
        colours: PropTypes.shape({
            workspace: PropTypes.string,
            flyout: PropTypes.string,
            toolbox: PropTypes.string,
            toolboxSelected: PropTypes.string,
            scrollbar: PropTypes.string,
            scrollbarHover: PropTypes.string,
            insertionMarker: PropTypes.string,
            insertionMarkerOpacity: PropTypes.number,
            fieldShadow: PropTypes.string,
            dragShadowOpacity: PropTypes.number
        }),
        comments: PropTypes.bool,
        collapse: PropTypes.bool
    }),
    reduxProjectId: PropTypes.string,
    setBlock: PropTypes.func.isRequired,
    setCategory: PropTypes.func.isRequired,
    setCustomBlock: PropTypes.func.isRequired,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    toolboxDisplayMode: PropTypes.oneOf([
        BLOCK_VIEW_MODES.STUDENT,
        BLOCK_VIEW_MODES.HIDE,
        BLOCK_VIEW_MODES.SHOW,
        BLOCK_VIEW_MODES.CONFIG
    ]).isRequired,
    toolboxXML: PropTypes.string,
    updateToolboxState: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

Blocks.defaultOptions = {
    zoom: {
        controls: true,
        wheel: true,
        startScale: 0.675
    },
    grid: {
        spacing: 40,
        length: 2,
        colour: '#ddd'
    },
    colours: {
        workspace: '#F9F9F9',
        flyout: '#F9F9F9',
        toolbox: '#FFFFFF',
        toolboxSelected: '#E9EEF2',
        scrollbar: '#CECDCE',
        scrollbarHover: '#CECDCE',
        insertionMarker: '#000000',
        insertionMarkerOpacity: 0.2,
        fieldShadow: 'rgba(255, 255, 255, 0.3)',
        dragShadowOpacity: 0.6
    },
    comments: true,
    collapse: false,
    sounds: false
};

Blocks.defaultProps = {
    isVisible: true,
    options: Blocks.defaultOptions
};

const mapStateToProps = state => {
    // destructure to clean up the return object and streamline pre-return logic
    const {apiBlocks, interfaceMode} = state.scratchGui.ninedotsState;
    const {pointerMode, userMode, viewMode} = interfaceMode;

    const isPointerConfigModeProp = isPointerConfigMode(interfaceMode);

    // determine toolbox display mode
    let toolboxDisplayMode;
    if (isPointerConfigModeProp) {
        toolboxDisplayMode = BLOCK_VIEW_MODES.CONFIG;
    } else if (viewMode === VIEW_MODE_SHOW) {
        toolboxDisplayMode = BLOCK_VIEW_MODES.SHOW;
    } else if (userMode === USER_MODE_CREATOR) {
        toolboxDisplayMode = BLOCK_VIEW_MODES.HIDE;
    } else {
        toolboxDisplayMode = BLOCK_VIEW_MODES.STUDENT;
    }

    return {
        anyModalVisible: (
            Object.keys(state.scratchGui.modals).some(key => state.scratchGui.modals[key]) ||
            state.scratchGui.mode.isFullScreen
        ),
        extensionLibraryVisible: state.scratchGui.modals.extensionLibrary,
        isRtl: state.locales.isRtl,
        locale: state.locales.locale,
        messages: state.locales.messages,
        toolboxXML: state.scratchGui.toolbox.toolboxXML,
        customProceduresVisible: state.scratchGui.customProcedures.active,
        apiBlocks,
        isPointerConfigMode: isPointerConfigModeProp,
        isPointerDefaultMode: pointerMode === POINTER_MODE_DEFAULT,
        isPointerLockMode: pointerMode === POINTER_MODE_LOCK,
        isStudent: interfaceMode.stackMode === STACK_MODE_STUDENT_COPY,
        reduxProjectId: state.scratchGui.projectState.projectId,
        toolboxDisplayMode
    };
};

const mapDispatchToProps = dispatch => ({
    onActivateColorPicker: callback => dispatch(activateColorPicker(callback)),
    onActivateCustomProcedures: (data, callback) => dispatch(activateCustomProcedures(data, callback)),
    onOpenConnectionModal: id => {
        dispatch(setConnectionModalExtensionId(id));
        dispatch(openConnectionModal());
    },
    onOpenSoundRecorder: () => {
        dispatch(activateTab(SOUNDS_TAB_INDEX));
        dispatch(openSoundRecorder());
    },
    onRequestCloseExtensionLibrary: () => {
        dispatch(closeExtensionLibrary());
    },
    onRequestCloseCustomProcedures: data => {
        dispatch(deactivateCustomProcedures(data));
    },
    updateToolboxState: toolboxXML => {
        dispatch(updateToolbox(toolboxXML));
    },
    // Stack / 9 Dots
    completeChallenge: message => {
        dispatch(completeChallenge(message));
        dispatch(openCompletionModal());
    },
    failChallenge: message => {
        dispatch(failChallenge(message));
        dispatch(openCompletionModal());
    },
    setBlock: (blockName, isEnabled) => {
        dispatch(setBlock(blockName, isEnabled));
    },
    setCategory: (categoryName, isEnabled) => {
        dispatch(setCategory(categoryName, isEnabled));
    },
    addCustomBlock: (categoryName, blockName) => {
        dispatch(addCustomBlock(categoryName, blockName));
    },
    deleteCustomBlock: (categoryName, blockName) => {
        dispatch(deleteCustomBlock(categoryName, blockName));
    },
    setCustomBlock: (categoryName, blockName, isEnabled) => {
        dispatch(setCustomBlock(categoryName, blockName, isEnabled));
    }
});

export default errorBoundaryHOC('Blocks')(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Blocks)
);
