/**
 * @file Provides Firebase functionality to be used with/by storage.
 * @author Julius Diaz Panoriñgan
 */

import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyBZa0rzpPiLShpoyn4VN8mTEFzVadeslCY',
  authDomain: 'scratch-27e39.firebaseapp.com',
  databaseURL: 'https://scratch-27e39.firebaseio.com',
  projectId: 'scratch-27e39',
  storageBucket:
    process.env.CLOUD_STORAGE_BUCKET_NAME || 'ninedots-scratch-dev',
  messagingSenderId: '439710230024'
}
/* const firebaseApp = */ firebase.initializeApp(config)

/**
 * The Firebase Storage instance to be exported.
 * @constant
 * @type {Object}
 * @exports firebaseStorage
 */
export const firebaseStorage = firebase.storage()

/**
 * The Firestore instance to be exported.
 * @constant
 * @type {Object}
 * @exports firestore
 */
export const firestore = firebase.firestore()
firestore.settings({ timestampsInSnapshots: true })
