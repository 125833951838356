/**
 * @file Redux reducer for managing interface modes (creator vs. student;
 * default vs. hide vs. lock; show vs. hide).
 * @author Julius Diaz Panoriñgan
 */

import { MERGE_NINEDOTS_STATE } from './ninedots-root'
import { SET_ADD_SOLUTION_STEP } from './step-in-creator-flow'

// action types
const SET_POINTER_MODE =
  'stack-gui/ninedots-root/interface-mode/SET_POINTER_MODE'
const SET_USER_MODE = 'stack-gui/ninedots-root/interface-mode/SET_USER_MODE'
const SET_VIEW_MODE = 'stack-gui/ninedots-root/interface-mode/SET_VIEW_MODE'

// constants for interface modes, to be used by action creators and reducer
const STACK_MODE_PREVIEW = 'PREVIEW'
const STACK_MODE_SOLUTION = 'SOLUTION'
const STACK_MODE_STUDENT_COPY = 'STUDENT_COPY'
const STACK_MODE_TEMPLATE = 'TEMPLATE'
const POINTER_MODE_DEFAULT = 'POINTER_MODE_DEFAULT'
const POINTER_MODE_HIDE = 'POINTER_MODE_HIDE'
const POINTER_MODE_LOCK = 'POINTER_MODE_LOCK'
const USER_MODE_CREATOR = 'USER_MODE_CREATOR'
const USER_MODE_STUDENT = 'USER_MODE_STUDENT'
const VIEW_MODE_HIDE = 'VIEW_MODE_HIDE'
const VIEW_MODE_SHOW = 'VIEW_MODE_SHOW'

// these Stack interface modes should not be changed
const lockedStackInterfaces = [
  STACK_MODE_PREVIEW,
  STACK_MODE_SOLUTION,
  STACK_MODE_STUDENT_COPY
]
// these are the interface parameters for locked modes
const lockedInterfaceState = {
  pointerMode: POINTER_MODE_DEFAULT,
  userMode: USER_MODE_STUDENT,
  viewMode: VIEW_MODE_HIDE
}

// initial state
// initial states for each mode
const initialStates = {
  [STACK_MODE_PREVIEW]: {
    stackMode: STACK_MODE_PREVIEW,
    ...lockedInterfaceState
  },
  [STACK_MODE_SOLUTION]: {
    stackMode: STACK_MODE_SOLUTION,
    ...lockedInterfaceState
  },
  [STACK_MODE_STUDENT_COPY]: {
    stackMode: STACK_MODE_STUDENT_COPY,
    ...lockedInterfaceState
  },
  [STACK_MODE_TEMPLATE]: {
    stackMode: STACK_MODE_TEMPLATE,
    pointerMode: POINTER_MODE_DEFAULT,
    userMode: USER_MODE_CREATOR,
    viewMode: VIEW_MODE_HIDE
  }
}
// the primary initial state is template mode
const initialState = initialStates[STACK_MODE_TEMPLATE]

// helpful getters

const isClassroomStackMode = function (state) {
  return (
    state.stackMode === STACK_MODE_STUDENT_COPY ||
    state.stackMode === STACK_MODE_PREVIEW
  )
}

const isPointerConfigMode = function (state) {
  return state.pointerMode !== POINTER_MODE_DEFAULT
}

// reducer
const reducer = function (state = initialState, action) {
  // on load, set up initial state depending on type
  if (action.type === MERGE_NINEDOTS_STATE) {
    return action.stateToMerge !== undefined
      ? initialStates[action.stateToMerge.type]
      : state
  }

  // interface mode should not be changed for locked interface types
  if (lockedStackInterfaces.includes(state.type)) {
    return state
  }

  // when adding a solution in the creator flow, switch modes
  if (action.type === SET_ADD_SOLUTION_STEP) {
    return initialStates[STACK_MODE_SOLUTION]
  }

  const isCurrentlyStudentMode = state.userMode === USER_MODE_STUDENT
  const isSettingStudentMode =
    action.type === SET_USER_MODE && action.userMode === USER_MODE_STUDENT
  const isSettingConfigMode =
    action.type === SET_POINTER_MODE &&
    action.pointerMode !== POINTER_MODE_DEFAULT
  const isSettingHideMode =
    action.type === SET_VIEW_MODE && action.viewMode === VIEW_MODE_HIDE

  switch (action.type) {
    case SET_POINTER_MODE:
      // set pointer mode only if not in student mode
      return isCurrentlyStudentMode
        ? state
        : {
          ...state,
          pointerMode: action.pointerMode,
          // if setting a config mode, set view mode to show hidden
          viewMode: isSettingConfigMode ? VIEW_MODE_SHOW : state.viewMode
        }
    case SET_USER_MODE:
      return {
        // ...state,
        // also set pointer mode to default if switching to student mode
        pointerMode: isSettingStudentMode
          ? POINTER_MODE_DEFAULT
          : state.pointerMode,
        userMode: action.userMode,
        // also set view mode to hide if switching to student mode
        viewMode: isSettingStudentMode ? VIEW_MODE_HIDE : state.viewMode
      }
    case SET_VIEW_MODE:
      // set view mode only if not in student mode
      return isCurrentlyStudentMode
        ? state
        : {
          ...state,
          viewMode: action.viewMode,
          // if setting hide mode, set pointer mode to default
          pointerMode: isSettingHideMode
            ? POINTER_MODE_DEFAULT
            : state.pointerMode
        }
    default:
      return state
  }
}

// action creators
const setPointerMode = function (pointerMode) {
  return {
    type: SET_POINTER_MODE,
    pointerMode
  }
}
const setUserMode = function (userMode) {
  return {
    type: SET_USER_MODE,
    userMode
  }
}
const setViewMode = function (viewMode) {
  return {
    type: SET_VIEW_MODE,
    viewMode
  }
}

export {
  reducer as default,
  initialState as interfaceModeInitialState,
  isClassroomStackMode,
  isPointerConfigMode,
  setPointerMode,
  setUserMode,
  setViewMode,
  STACK_MODE_PREVIEW,
  STACK_MODE_SOLUTION,
  STACK_MODE_STUDENT_COPY,
  STACK_MODE_TEMPLATE,
  POINTER_MODE_DEFAULT,
  POINTER_MODE_HIDE,
  POINTER_MODE_LOCK,
  USER_MODE_CREATOR,
  USER_MODE_STUDENT,
  VIEW_MODE_HIDE,
  VIEW_MODE_SHOW
}
