/**
 * @file Redux reducer for enabling/disabling UI elements in the Stack GUI.
 * @author Julius Diaz Panoriñgan
 */

import { MERGE_NINEDOTS_STATE } from './ninedots-root'

// action types
const TOGGLE_BACKDROP_MENU =
  'stack-gui/ninedots-root/ui-elements/TOGGLE_BACKDROP_MENU'
const TOGGLE_COSTUMES_TAB =
  'stack-gui/ninedots-root/ui-elements/TOGGLE_COSTUMES_TAB'
const TOGGLE_SOUNDS_TAB =
  'stack-gui/ninedots-root/ui-elements/TOGGLE_SOUNDS_TAB'
const TOGGLE_SPRITE_MENU =
  'stack-gui/ninedots-root/ui-elements/TOGGLE_SPRITE_MENU'

// constants, to be used by action creators and reducer

// initial state
const initialState = {
  backdropMenuEnabled: true,
  costumesTabEnabled: true,
  soundsTabEnabled: true,
  spriteMenuEnabled: true
}

// reducer
const reducer = function (state = initialState, action) {
  switch (action.type) {
    case MERGE_NINEDOTS_STATE:
      return action.stateToMerge !== undefined &&
        action.stateToMerge.uiElements !== undefined
        ? {
          ...state,
          ...action.stateToMerge.uiElements
        }
        : state
    case TOGGLE_BACKDROP_MENU:
      return {
        ...state,
        backdropMenuEnabled: !state.backdropMenuEnabled
      }
    case TOGGLE_COSTUMES_TAB:
      return {
        ...state,
        costumesTabEnabled: !state.costumesTabEnabled
      }
    case TOGGLE_SOUNDS_TAB:
      return {
        ...state,
        soundsTabEnabled: !state.soundsTabEnabled
      }
    case TOGGLE_SPRITE_MENU:
      return {
        ...state,
        spriteMenuEnabled: !state.spriteMenuEnabled
      }
    default:
      return state
  }
}

// action creators
const toggleBackdropMenu = function () {
  return {
    type: TOGGLE_BACKDROP_MENU
  }
}

const toggleCostumesTab = function () {
  return {
    type: TOGGLE_COSTUMES_TAB
  }
}

const toggleSoundsTab = function () {
  return {
    type: TOGGLE_SOUNDS_TAB
  }
}

const toggleSpriteMenu = function () {
  return {
    type: TOGGLE_SPRITE_MENU
  }
}

export {
  reducer as default,
  initialState as uiElementsInitialState,
  toggleBackdropMenu,
  toggleCostumesTab,
  toggleSoundsTab,
  toggleSpriteMenu
  // constants
}
