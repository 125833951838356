import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Box from '../box/box.jsx';
import ActionMenu from '../action-menu/action-menu.jsx';
import styles from './stage-selector.css';
import {isRtl} from 'scratch-l10n';

import backdropIcon from '../action-menu/icon--backdrop.svg';
import fileUploadIcon from '../action-menu/icon--file-upload.svg';
import paintIcon from '../action-menu/icon--paint.svg';
import surpriseIcon from '../action-menu/icon--surprise.svg';
import searchIcon from '../action-menu/icon--search.svg';

import hiddenIcon from '../sprite-info/icon--hide.svg';

const messages = defineMessages({
    addBackdropFromLibrary: {
        id: 'gui.spriteSelector.addBackdropFromLibrary',
        description: 'Button to add a stage in the target pane from library',
        defaultMessage: 'Choose a Backdrop'
    },
    addBackdropFromPaint: {
        id: 'gui.stageSelector.addBackdropFromPaint',
        description: 'Button to add a stage in the target pane from paint',
        defaultMessage: 'Paint'
    },
    addBackdropFromSurprise: {
        id: 'gui.stageSelector.addBackdropFromSurprise',
        description: 'Button to add a random stage in the target pane',
        defaultMessage: 'Surprise'
    },
    addBackdropFromFile: {
        id: 'gui.stageSelector.addBackdropFromFile',
        description: 'Button to add a stage in the target pane from file',
        defaultMessage: 'Upload Backdrop'
    }
});

const StageSelector = props => {
    const {
        backdropCount,
        containerRef,
        dragOver,
        fileInputRef,
        intl,
        selected,
        raised,
        receivedBlocks,
        url,
        onBackdropFileUploadClick,
        onBackdropFileUpload,
        onClick,
        onMouseEnter,
        onMouseLeave,
        onNewBackdropClick,
        onSurpriseBackdropClick,
        onEmptyBackdropClick,
        backdropMenuEnabled,
        isPointerConfigMode,
        isShowHiddenMode,
        onBackdropMenuClick,
        ...componentProps
    } = props;

    /*
        set img, buttons, and title tooltip depending on pointer mode and
        whether the backdrop menu is enabled or not
    */

    // if backdrop menu disabled, indicate hidden status w/ alternate icon
    const img = backdropMenuEnabled ?
        backdropIcon :
        hiddenIcon;

    // if in hide/lock mode, disable extra float-out menu options
    const moreButtons = isPointerConfigMode ?
        null :
        [
            {
                title: intl.formatMessage(messages.addBackdropFromFile),
                img: fileUploadIcon,
                onClick: onBackdropFileUploadClick,
                fileAccept: '.svg, .png, .jpg, .jpeg', // Bitmap coming soon
                fileChange: onBackdropFileUpload,
                fileInput: fileInputRef
            }, {
                title: intl.formatMessage(messages.addBackdropFromSurprise),
                img: surpriseIcon,
                onClick: onSurpriseBackdropClick

            }, {
                title: intl.formatMessage(messages.addBackdropFromPaint),
                img: paintIcon,
                onClick: onEmptyBackdropClick
            }, {
                title: intl.formatMessage(messages.addBackdropFromLibrary),
                img: searchIcon,
                onClick: onNewBackdropClick
            }
        ];

    // display appropriate tooltip text
    const title = isPointerConfigMode ?
        (backdropMenuEnabled ? 'Disable Backdrop Menu' : 'Enable Backdrop Menu') :
        intl.formatMessage(messages.addBackdropFromLibrary); // default message

    return (
        <Box
            className={classNames(styles.stageSelector, {
                [styles.isSelected]: selected,
                [styles.raised]: raised || dragOver,
                [styles.receivedBlocks]: receivedBlocks
            })}
            componentRef={containerRef}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...componentProps}
        >
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <FormattedMessage
                        defaultMessage="Stage"
                        description="Label for the stage in the stage selector"
                        id="gui.stageSelector.stage"
                    />
                </div>
            </div>
            {url ? (
                <img
                    className={styles.costumeCanvas}
                    src={url}
                />
            ) : null}
            <div className={styles.label}>
                <FormattedMessage
                    defaultMessage="Backdrops"
                    description="Label for the backdrops in the stage selector"
                    id="gui.stageSelector.backdrops"
                />
            </div>
            <div className={styles.count}>{backdropCount}</div>
            {(isShowHiddenMode || backdropMenuEnabled) &&
                <ActionMenu
                    className={styles.addButton}
                    img={img}
                    moreButtons={moreButtons}
                    title={title}
                    tooltipPlace={isRtl(intl.locale) ? 'right' : 'left'}
                    onClick={onBackdropMenuClick}
                />
            }

        </Box>
    );
};

StageSelector.propTypes = {
    backdropCount: PropTypes.number.isRequired,
    backdropMenuEnabled: PropTypes.bool.isRequired,
    containerRef: PropTypes.func,
    dragOver: PropTypes.bool,
    fileInputRef: PropTypes.func,
    intl: intlShape.isRequired,
    isPointerConfigMode: PropTypes.bool.isRequired,
    isShowHiddenMode: PropTypes.bool.isRequired,
    onBackdropFileUpload: PropTypes.func,
    onBackdropFileUploadClick: PropTypes.func,
    onBackdropMenuClick: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onEmptyBackdropClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onNewBackdropClick: PropTypes.func,
    onSurpriseBackdropClick: PropTypes.func,
    raised: PropTypes.bool.isRequired,
    receivedBlocks: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    url: PropTypes.string
};

export default injectIntl(StageSelector);
