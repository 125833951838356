/**
 * @file Utility functions for the 9 Dots platform.
 * @author Julius Diaz Panoriñgan
 */

/**
 * Given the id of a Stack project, attempts to send an update (indicating 100%
 * completion) to the 9 Dots platform.
 * Note that sending a completion update doesn't mean anything for a project
 * that isn't of type 'STUDENT_COPY'.
 * @function updateCompletion
 * @exports
 * @param {string} stackId The id of a Stack project.
 */
export function updateCompletion (stackId) {
  fetch(`/internalapi/complete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ stackId })
  })
}
