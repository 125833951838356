import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import Box from '../../../components/box/box.jsx'

import styles from './submit-modal.css'

const SubmitModal = props => {
  let header
  switch (props.solutionSaveState) {
    case 'UNSAVED':
      header = 'Save to get 9 Dots platform link'
      break
    case 'SAVING':
      header = 'Saving...'
      break
    case 'SAVED':
      header = 'Use this link in the 9 Dots platform:'
      break
  }

  return (
    <ReactModal
      isOpen
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
      onRequestClose={props.onCancel}>
      <div dir={'ltr'}>
        <Box className={styles.illustration} />

        <Box className={styles.body}>
          <h2>{header}</h2>
          {props.solutionSaveState === 'SAVED' ? (
            <p className={styles.selectableLink}>
              {`${process.env.URL_BASE}#${props.projectId}`}
            </p>
          ) : (
            <p>{'...'}</p>
          )}
          <Box className={styles.buttonRow}>
            {props.solutionSaveState === 'UNSAVED' && (
              <React.Fragment>
                <button className={styles.noButton} onClick={props.onCancel}>
                  {'Cancel'}
                </button>
                <button
                  className={styles.saveProjectButton}
                  onClick={props.onSubmit}>
                  {'Save'}
                </button>
              </React.Fragment>
            )}
            {props.solutionSaveState === 'SAVED' && (
              <button className={styles.closeButton} onClick={props.onCancel}>
                {'Close'}
              </button>
            )}
          </Box>
        </Box>
      </div>
    </ReactModal>
  )
}

SubmitModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  solutionSaveState: PropTypes.oneOf(['UNSAVED', 'SAVING', 'SAVED'])
}

export default SubmitModal
