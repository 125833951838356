import React from 'react'
import classNames from 'classnames'
import styles from './toggle-switch.css'
import PropTypes from 'prop-types'

const ToggleSwitch = ({isOn, onSwitch}) => (
  <div className={classNames(styles.switchWrapper)}>
    <label className={classNames(styles.switch)}>
      <input
        type='checkbox'
        checked={isOn}
        onClick={onSwitch} />
      <span className={classNames(styles.slider, styles.round)} />
    </label>
    <div id={classNames(styles.switchPrompt)}>{'Show Hidden'}</div>
  </div>
)

ToggleSwitch.propTypes = {
  onSwitch: PropTypes.func
}

export default ToggleSwitch
