/**
 * @file Redux reducer for enabling/disabling API blocks in the Stack GUI.
 * @author Julius Diaz Panoriñgan
 */

import { MERGE_NINEDOTS_STATE } from './ninedots-root'

import {
  blockNames,
  dynamicCategories,
  getCategoryName
} from '../lib/block-utilities'

// action types
const SET_BLOCK = 'stack-gui/ninedots-root/api-blocks/SET_BLOCK'
const SET_CATEGORY = 'stack-gui/ninedots-root/api-blocks/SET_CATEGORY'
const ADD_CUSTOM_BLOCK = 'stack-gui/ninedots-root/api-blocks/ADD_CUSTOM_BLOCK'
const DELETE_CUSTOM_BLOCK =
  'stack-gui/ninedots-root/api-blocks/DELETE_CUSTOM_BLOCK'
const SET_CUSTOM_BLOCK = 'stack-gui/ninedots-root/api-blocks/SET_CUSTOM_BLOCK'

// constants, to be used by action creators and reducer
// (none yet)

// initialize initial state
const initialState = {}
Object.keys(blockNames).forEach(categoryName => {
  const categoryBlocks = {}

  if (dynamicCategories.includes(categoryName)) {
    categoryBlocks.staticBlocks = {}
    categoryBlocks.dynamicBlocks = {} // will contain { isEnabled: boolean, etc }
    blockNames[categoryName].forEach(blockName => {
      categoryBlocks.staticBlocks[blockName] = true
    })
  } else {
    blockNames[categoryName].forEach(blockName => {
      categoryBlocks[blockName] = true
    })
  }

  initialState[categoryName] = categoryBlocks
})

// getters

/**
 * Getter function for the state of a non-custom block. Given the state and the
 * name of a block, returns a boolean indicating its current state, or
 * undefined if the block is not present in state.
 * @function getBlockState
 * @param {object} state
 * @param {string} blockName
 * @returns {boolean|undefined}
 */
const getBlockState = function (state, blockName) {
  const categoryName = getCategoryName(blockName)

  if (dynamicCategories.includes(categoryName)) {
    return state[categoryName].staticBlocks[blockName]
  } else if (categoryName && state[categoryName]) {
    return state[categoryName][blockName]
  } else {
    return undefined
  }
}

/**
 * Getter function for the state of a custom block. Given the state, a category
 * name, and a block name, returns a boolean indicating its current state, or
 * undefined if the block is not present in state.
 * @function getCustomBlockState
 * @param {object} state
 * @param {string} categoryName
 * @param {string} blockName
 * @returns {boolean|undefined}
 */
const getCustomBlockState = function (state, categoryName, blockName) {
  if (!dynamicCategories.includes(categoryName)) {
    return undefined
  } else {
    const blockStatus = state[categoryName].dynamicBlocks[blockName]
    return blockStatus && blockStatus.isEnabled
  }
}

/**
 * Helper function to check state of a category with static and dynamic blocks.
 * See {@link getCategoryState}.
 * @function getDynamicCategoryState
 * @param {object} category
 * @returns {boolean|undefined}
 */
const getDynamicCategoryState = function (category) {
  const { staticBlocks, dynamicBlocks } = category
  const staticNames = Object.keys(staticBlocks)
  const dynamicNames = Object.keys(dynamicBlocks)
  const isEnabled = staticBlocks[staticNames[0]]

  return staticNames.every(name => staticBlocks[name] === isEnabled) &&
    dynamicNames.every(name => dynamicBlocks[name].isEnabled === isEnabled)
    ? isEnabled
    : undefined
}

/**
 * Getter function for the state of a particular category. Given the state and
 * the name of a category, returns true or false appropriately if all the
 * blocks in that category are enabled or disabled. If there are mixed states,
 * or the category is not defined, returns undefined.
 * @function getCategoryState
 * @param {object} state
 * @param {string} categoryName
 * @returns {boolean|undefined}
 */
const getCategoryState = function (state, categoryName) {
  // return undefined if category does not exist
  const category = state[categoryName]
  if (!category) {
    return undefined
  }

  // return undefined if no blocks in category
  const categoryBlocks = Object.keys(category)
  if (categoryBlocks.length === 0) {
    return undefined
  }

  // if necessary, use helper for category w/ dynamic blocks
  if (dynamicCategories.includes(categoryName)) {
    return getDynamicCategoryState(category)
  }

  // for static-block-only categories,
  // return true/false if all blocks share the same state, or undefined
  const isEnabled = category[categoryBlocks[0]]
  return categoryBlocks.every(block => category[block] === isEnabled)
    ? isEnabled
    : undefined
}

// utilities

/**
 * Lazily updates an old apiBlocks to an updated apiBlocks.
 * Currently, reorganizes the variables and myBlocks objects to have separate
 * objects representing static and dynamic blocks.
 * @function updateCustomBlockStructure
 * @param {object} apiBlocks
 * @returns {object}
 */
const updateCustomBlockStructure = function (apiBlocks) {
  return apiBlocks.variables.staticBlocks && apiBlocks.myBlocks.staticBlocks
    ? apiBlocks
    : {
      ...apiBlocks,
      variables: {
        staticBlocks: apiBlocks.variables,
        dynamicBlocks: {}
      },
      myBlocks: {
        staticBlocks: apiBlocks.myBlocks,
        dynamicBlocks: {}
      }
    }
}

/**
 * Enables/disables a given (static) block.
 * Note the ternaries in this function ensure the state object is appropriately
 * replaced with the original state object instead of a deeply equal state,
 * when nothing has changed.
 * @function setBlockReducer
 * @param {object} state
 * @param {string} blockName
 * @param {boolean} isEnabled
 * @returns {object}
 */
const setBlockReducer = function (state, blockName, isEnabled) {
  const categoryName = getCategoryName(blockName)

  if (dynamicCategories.includes(categoryName)) {
    return state[categoryName].staticBlocks[blockName] !== isEnabled
      ? {
        ...state,
        [categoryName]: {
          staticBlocks: {
            ...state[categoryName].staticBlocks,
            [blockName]: isEnabled
          },
          dynamicBlocks: state[categoryName].dynamicBlocks
        }
      }
      : state
  } else {
    return categoryName &&
      state[categoryName] &&
      state[categoryName][blockName] !== isEnabled
      ? {
        ...state,
        [categoryName]: {
          ...state[categoryName],
          [blockName]: isEnabled
        }
      }
      : state
  }
}

/**
 * Enables/disables all blocks in a category.
 * @function setCategoryReducer
 * @param {object} state
 * @param {string} categoryName
 * @param {boolean} isEnabled
 * @returns {object}
 */
const setCategoryReducer = function (state, categoryName, isEnabled) {
  let categoryUpdate

  if (dynamicCategories.includes(categoryName)) {
    const staticBlocks = {}
    const dynamicBlocks = {}
    Object.keys(state[categoryName].staticBlocks).forEach(staticBlockName => {
      staticBlocks[staticBlockName] = isEnabled
    })
    const originalDynamicBlocks = state[categoryName].dynamicBlocks
    Object.keys(originalDynamicBlocks).forEach(dynamicBlockName => {
      dynamicBlocks[dynamicBlockName] = {
        ...originalDynamicBlocks[dynamicBlockName],
        isEnabled
      }
    })
    categoryUpdate = {
      staticBlocks,
      dynamicBlocks
    }
  } else {
    categoryUpdate = { ...state[categoryName] }
    Object.keys(categoryUpdate).forEach(categoryBlockName => {
      categoryUpdate[categoryBlockName] = isEnabled
    })
  }

  return {
    ...state,
    [categoryName]: categoryUpdate
  }
}

/**
 * Adds a flag (initialized to { isEnabled: true }) in redux state
 * for the given custom block in the given dynamic category.
 * @function addCustomBlockReducer
 * @param {object} state
 * @param {string} categoryName
 * @param {string} blockName
 * @returns {object}
 */
const addCustomBlockReducer = function (state, categoryName, blockName) {
  // let's be sure this is a dynamic category
  if (!dynamicCategories.includes(categoryName)) {
    return state
  }

  // the custom block already exists,
  // e.g. a block event is being created when switching to a new sprite
  // for an already-existing variable
  if (state[categoryName].dynamicBlocks[blockName]) {
    return state
  }

  return {
    ...state,
    [categoryName]: {
      staticBlocks: state[categoryName].staticBlocks,
      dynamicBlocks: {
        ...state[categoryName].dynamicBlocks,
        [blockName]: {
          isEnabled: true
        }
      }
    }
  }
}

/**
 * Deletes the flag in redux state
 * for the given custom block in the given dynamic category.
 * @function deleteCustomBlockReducer
 * @param {object} state
 * @param {string} categoryName
 * @param {string} blockName
 * @returns {object}
 */
const deleteCustomBlockReducer = function (state, categoryName, blockName) {
  // let's be sure this is a dynamic category
  if (!dynamicCategories.includes(categoryName)) {
    return state
  }

  // the custom block does not exist?!
  if (state[categoryName].dynamicBlocks[blockName] === undefined) {
    return state
  }

  const updatedDynamicBlocks = {
    ...state[categoryName].dynamicBlocks
  }
  delete updatedDynamicBlocks[blockName]

  return {
    ...state,
    [categoryName]: {
      staticBlocks: state[categoryName].staticBlocks,
      dynamicBlocks: updatedDynamicBlocks
    }
  }
}

/**
 * Enables/disables the given custom (i.e. dynamic) block in the given category.
 * @function setCustomBlockReducer
 * @param {object} state
 * @param {string} categoryName
 * @param {string} blockName
 * @param {boolean} isEnabled
 */
const setCustomBlockReducer = function (
  state,
  categoryName,
  blockName,
  isEnabled
) {
  const currentBlockStatus = state[categoryName].dynamicBlocks[blockName]
  // the check on isEnabled below avoids returning a (deeply) identical state object
  return currentBlockStatus && currentBlockStatus.isEnabled !== isEnabled
    ? {
      ...state,
      [categoryName]: {
        staticBlocks: state[categoryName].staticBlocks,
        dynamicBlocks: {
          ...state[categoryName].dynamicBlocks,
          [blockName]: {
            ...currentBlockStatus,
            isEnabled
          }
        }
      }
    }
    : state
}

// reducer

const reducer = function (state = initialState, action) {
  const { blockName, categoryName, isEnabled } = action
  switch (action.type) {
    case MERGE_NINEDOTS_STATE:
      return action.stateToMerge !== undefined &&
        action.stateToMerge.apiBlocks !== undefined
        ? {
          ...state,
          ...updateCustomBlockStructure(action.stateToMerge.apiBlocks)
        }
        : state
    case SET_BLOCK:
      return setBlockReducer(state, blockName, isEnabled)
    case SET_CATEGORY:
      return setCategoryReducer(state, categoryName, isEnabled)
    case ADD_CUSTOM_BLOCK:
      return addCustomBlockReducer(state, categoryName, blockName)
    case DELETE_CUSTOM_BLOCK:
      return deleteCustomBlockReducer(state, categoryName, blockName)
    case SET_CUSTOM_BLOCK:
      return setCustomBlockReducer(state, categoryName, blockName, isEnabled)
    default:
      return state
  }
}

// action creators

const setBlock = function (blockName, isEnabled) {
  return {
    type: SET_BLOCK,
    blockName,
    isEnabled
  }
}

const setCategory = function (categoryName, isEnabled) {
  return {
    type: SET_CATEGORY,
    categoryName,
    isEnabled
  }
}

const addCustomBlock = function (categoryName, blockName) {
  return {
    type: ADD_CUSTOM_BLOCK,
    categoryName,
    blockName
  }
}

const deleteCustomBlock = function (categoryName, blockName) {
  return {
    type: DELETE_CUSTOM_BLOCK,
    categoryName,
    blockName
  }
}

const setCustomBlock = function (categoryName, blockName, isEnabled) {
  return {
    type: SET_CUSTOM_BLOCK,
    categoryName,
    blockName,
    isEnabled
  }
}

export {
  reducer as default,
  initialState as apiBlocksInitialState,
  // getters
  getBlockState,
  getCustomBlockState,
  getCategoryState,
  // action creators
  setBlock,
  setCategory,
  addCustomBlock,
  deleteCustomBlock,
  setCustomBlock
  // constants -- none yet
}
