/**
 * @file A redux reducer that manages all additional 9 Dots state.
 * @author Julius Diaz Panoriñgan
 */

import { combineReducers } from 'redux'

import apiBlocksReducer, { apiBlocksInitialState } from './api-blocks'
import completionReducer, { completionInitialState } from './completion'
import interfaceModeReducer, {
  interfaceModeInitialState
} from './interface-mode'
import stepInCreatorFlowReducer, {
  creatorFlowStepInitialState
} from './step-in-creator-flow'
import uiElementsReducer, { uiElementsInitialState } from './ui-elements'

// action types
const MERGE_NINEDOTS_STATE = 'stack-gui/ninedots-root/MERGE_NINEDOTS_STATE'

// constants, to be used by action creators and reducer

// initial state
const initialState = {
  apiBlocks: apiBlocksInitialState,
  completion: completionInitialState,
  interfaceMode: interfaceModeInitialState,
  stepInCreatorFlow: creatorFlowStepInitialState,
  uiElements: uiElementsInitialState
}

// reducer
const reducer = combineReducers({
  apiBlocks: apiBlocksReducer,
  completion: completionReducer,
  interfaceMode: interfaceModeReducer,
  stepInCreatorFlow: stepInCreatorFlowReducer,
  uiElements: uiElementsReducer
})

// action creators
const mergeNinedotsState = function (stateToMerge) {
  return {
    type: MERGE_NINEDOTS_STATE,
    stateToMerge
  }
}

export {
  reducer as default,
  initialState as ninedotsInitialState,
  mergeNinedotsState,
  MERGE_NINEDOTS_STATE
}
